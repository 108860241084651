import { Component, OnInit } from '@angular/core';
import { FooterCompanyName } from './../../constant/global-constants';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public year = new Date().getFullYear()
  public companyName = FooterCompanyName

  constructor(public _authService: AuthService) { }

  ngOnInit() {
  }

}
