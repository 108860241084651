import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicantCardStatusService } from 'src/app/services/applicant-card-status/appplicant-card-status-service';
import { ApplicantCardStatus } from 'src/app/models/applicant-card-status';
import { MyIdCardService } from '../../services/my-id-card/my-id-card.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  modalRef: BsModalRef | null
  selectedPost: any = {}
  statusOne: boolean = false;
  statusTwo: boolean = false;
  statusthree: boolean = false;

  applyCard: boolean = false;
  renewalCard: boolean = false;
  lostCard: boolean = false;
  myidCard: boolean = false;
  myRequestCard: boolean = false;

  appStatusDetail: ApplicantCardStatus;

  constructor(
    private _toastrService: ToastrService,
    private _myIdCardService: MyIdCardService,
    private _applicantCardStatusService: ApplicantCardStatusService
  ) { }

  ngOnInit() {
    this.applyCard = false;
    this.renewalCard = false;
    this.lostCard = false;
    this.myidCard = false;
    this.myRequestCard = false;
    this.appStatusDetail = new ApplicantCardStatus();
    this.getApplicantStatus();
  }

  htmlString: string;

  getApplicantStatus() {
    this._applicantCardStatusService.getApplicantCardStatusDetail(localStorage.getItem("mobileNo")).subscribe(res => {
      if (res.status == 200 && res.body && res.body["result"] && res.body["result"]["status"]) {
        this.appStatusDetail = res.body["result"]["status"]
        localStorage.setItem("currentStatus", this.appStatusDetail.currentStatus);
        localStorage.setItem("cardType", this.appStatusDetail.cardType);
        if (this.appStatusDetail.currentStatus == null) {
          this.applyCard = true;
          this.renewalCard = true;
          this.lostCard = true;
          this.myidCard = false;
          this.myRequestCard = false;
        } else if (this.appStatusDetail.currentStatus == 'APPROVED') {
          this.applyCard = false;
          this.renewalCard = true;
          this.lostCard = true;
          this.myidCard = true;
          this.myRequestCard = true;
        } else {
          this.applyCard = false;
          if (this.appStatusDetail.cardType == 'New Card') {
            this.applyCard = true;
          }

          this.renewalCard = false;
          if (this.appStatusDetail.cardType == 'Renewal Card') {
            this.renewalCard = true;
          }
          this.lostCard = false;
          this.myidCard = false;
          this.myRequestCard = true;
        }

      } else {
        this.applyCard = false;
        this.renewalCard = false;
        this.lostCard = false;
        this.myidCard = false;
        this.myRequestCard = false;
      }
    });

    // this._applicantCardStatusService.getApplicantStatus(localStorage.getItem("mobileNo")).subscribe((res) => {
    //   if (res && res["body"]) {
    //     let data = res["body"] != null ? res["body"] : null;
    //     localStorage.setItem("currentStatus", data.toString());
    //     if (data != null) {
    //       if (data == 1) {
    //         this.applyCard = true;
    //         this.renewalCard = false;
    //         this.lostCard = false;
    //         this.myidCard = false;
    //         this.myRequestCard = true;
    //       } else if (data == 2) {
    //         this.applyCard = false;
    //         this.renewalCard = true;
    //         this.lostCard = true;
    //         this.myidCard = true;
    //         this.myRequestCard = true;
    //       } else {
    //         this.applyCard = true;
    //         this.renewalCard = false;
    //         this.lostCard = true;
    //         this.myidCard = false;
    //         this.myRequestCard = false;
    //       }

    //     } else {
    //       this.applyCard = false;
    //       this.renewalCard = false;
    //       this.lostCard = false;
    //       this.myidCard = false;
    //       this.myRequestCard = false;
    //     }
    //   }
    // })

  }


  openPdf() {
    let mobileNo = localStorage.getItem('mobileNo');
    this._myIdCardService.geteSignPdfDetails(mobileNo).subscribe((res: any) => {
      if (res && res['statusCode'] == 200 && res['result']["image"]) {
        const binaryString = window.atob(res['result']["image"]);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        let blob = new Blob([bytes], { type: "application/pdf" })
        window.open(URL.createObjectURL(blob))
      } else {
        let message = res['message'] ? res['message'] : "File Not Found";
        this._toastrService.error("", message);
      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })
  }


}