import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // @ViewChild('otpone', { static: false }) otpone;
  // @ViewChild('otptwo', { static: false }) otptwo;
  // @ViewChild('otpthree', { static: false }) otpthree;
  // @ViewChild('otpfour', { static: false }) otpfour;
  loginForm: FormGroup;
  otpForm: FormGroup;
  submitted = false;
  hospitals = [];
  divisions = [];
  isOtpSend: boolean = false;
  otpSubmitted: boolean = false;
  editPhoneNumber: boolean = true;
  canResendOtp: any = '';

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _toastrService: ToastrService
  ) {
    this._authService.isUserLoggedInStatus ? this._router.navigate(['/']) : ''
  }

  ngOnInit() {
    // localStorage.clear();
    this.inItLoginForm();
    this.getAllDevisions();
    //this.getActiveHospitals();

  }

  inItLoginForm() {
    this.loginForm = this._formBuilder.group({
      divisionId: [null, [Validators.required, Validators.nullValidator]],
      hospitalId: [null, [Validators.required, Validators.nullValidator]],
      phoneNumber: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^-?(0|[0-9]\d*)?$/)
      ]],


    })
  }

  /**
   * 
   */

  sendOTP() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let phoneNo = this.loginForm.controls.phoneNumber.value;
    let hospital = this.loginForm.controls.hospitalId.value;
    let division = this.loginForm.controls.divisionId.value;
    localStorage.setItem("hospitalId", hospital);
    localStorage.setItem("divisionId", division);
    this._authService.sendOTP(phoneNo, hospital, division).subscribe((res) => {
      if (res && res["statusCode"] == 200) {
        this.isOtpSend = true;
        this._toastrService.info('', res["message"]);
        this.inItOTPForm();

      } else {
        this._toastrService.error('', "The login detail is incorrect.");
      }
    }, err => {
      this._toastrService.error('', "The login detail is incorrect.");
    })
  }

  resendOTP(phoneNumber: any) {
    this.loginForm.controls.phoneNumber.setValue(phoneNumber);
    this.sendOTP();
  }

  inItOTPForm() {
    this.otpForm = this._formBuilder.group({
      phoneNumber: [this.loginForm.controls.phoneNumber.value,
      [Validators.required,
      Validators.nullValidator,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
      otpOne: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^-?(0|[0-9]\d*)?$/)
      ]],
      otpTwo: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^-?(0|[0-9]\d*)?$/)
      ]],
      otpThree: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^-?(0|[0-9]\d*)?$/)
      ]],
      otpFour: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
        Validators.pattern(/^-?(0|[0-9]\d*)?$/)
      ]],
    })
  }

  /**
   * @info login form 
   */
  login() {
    this.otpSubmitted = true
    if (this.otpForm.invalid) {
      return;
    }
    let divisionId = localStorage.getItem("divisionId");
    let hospitalId = localStorage.getItem("hospitalId");
    let phoneNo = this.otpForm.controls.phoneNumber.value;
    let otp = this.otpForm.controls.otpOne.value
      + this.otpForm.controls.otpTwo.value
      + this.otpForm.controls.otpThree.value
      + this.otpForm.controls.otpFour.value;

    this._authService.verifyOtp(divisionId, hospitalId, phoneNo, otp).subscribe(res => {
      if (res && res["statusCode"] == 200) {
        let token = res['result']["token"];
        localStorage.setItem('currentStatus', res['result']["currentStatus"]);
        localStorage.setItem('token', token)
        localStorage.setItem('mobileNo', phoneNo)
        this._toastrService.success('', res["message"]);
        this.getLoggedInUser();
      } else {
        this._toastrService.error('', res["message"])
      }
    }, error => {
      this._toastrService.error('', "Something went wrong, please try again or after sometime.")
    })
  }

  getLoggedInUser() {
    this._authService.getUserByToken().subscribe((res) => {
      if (res) {
        localStorage.setItem('currentUser', JSON.stringify(res));
        this._authService.isUserLoggedIn();
        this._router.navigate(['/'])
      }
    })
  }


  /**
   * @get all active hospital list
   */
  getActiveHospitals() {
    this._authService.getActiveHospitals().subscribe((res) => {
      if (res && res["statusCode"] == 200) {
        this.hospitals = res["result"] && res["result"]["hospitals"] ? res["result"]["hospitals"] : [];
        // this.hospitals.splice(0, 0, { id: null, "name": "- Select Hospital -" });
      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })
  }
  /**
   * get all divisions
   */
  getAllDevisions() {
    this._authService.getAllDevisions().subscribe((res: any) => {
      if (res) {
        this.divisions = res;
      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })
  }

  onDivisionChange(divisionId) {
    this.hospitalByDivisionId(divisionId);
  }

  /**
   * get hospital by division
   */
  hospitalByDivisionId(divisionId) {
    this._authService.hospitalByDivisionId(divisionId).subscribe((res) => {
      if (res && res["statusCode"] == 200) {
        this.hospitals = res["result"] && res["result"]["hospitals"] ? res["result"]["hospitals"] : [];
      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })

  }

  resetData() {
    this.isOtpSend = false;
    this.otpSubmitted = false;
    this.submitted = false;
    this.loginForm.reset();
    this.otpForm.reset();
    this.ngOnInit();
  }

  // convenience getter for easy access to form fields
  get loginControl() { return this.loginForm.controls; }

  // convenience getter for easy access to form fields
  get otpControl() { return this.otpForm.controls; }

  onInputEntry(event, id) {
    if (event.data != null && event.data >= 0 && event.data <= 9) {
      document.getElementById(id).focus();
    }
  }


}