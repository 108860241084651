import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpConfigInterceptor } from './auth/interceptor/httpconfing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UsersComponent } from './components/users/users.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { PaginationDetailComponent } from './components/common/pagination-detail/pagination-detail.component';
import { AdmitCardComponent } from './components/admit-card/admit-card.component';
import { MyRequestComponent } from './components/my-request/my-request.component';
import { MyIdCardComponent } from './components/my-id-card/my-id-card.component';
import { LostCardComponent } from './components/lost-card/lost-card.component';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { NgxPageScrollModule } from 'ngx-page-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from './services/common/translation.service';
import { LostCardService } from './services/lostCard/lost-card.service';
import { CountdownModule } from 'ngx-countdown';
import { RenewalCardComponent } from './components/renewal-card/renewal-card.component';
//import { myEasing } from './shared/constant/global-constants';
import { LightboxModule } from 'ngx-lightbox';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileValueAccessor } from './auth/interceptor/file-value-accessor';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    AboutusComponent,
    ContactusComponent,
    PageNotFoundComponent,
    UsersComponent,
    LoaderComponent,
    PaginationDetailComponent,
    AdmitCardComponent,
    MyRequestComponent,
    MyIdCardComponent,
    LostCardComponent,
    ConfirmComponent,
    RenewalCardComponent,
    FileValueAccessor
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    TranslateModule.forRoot(),
    CountdownModule,
    SlickCarouselModule,
    ToastrModule.forRoot({ timeOut: 5000, closeButton: true, enableHtml: true, positionClass: 'toast-bottom-right', preventDuplicates: true, progressBar: true }),
    SimpleModalModule.forRoot({ container: 'modal-container' }),
    NgxPageScrollCoreModule.forRoot({ duration: 2000, scrollOffset: 111 }),
    NgxPageScrollModule,
    LightboxModule
  ],
  providers: [
    LostCardService,
    TranslationService,
    NgxImageCompressService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  entryComponents: [
    ConfirmComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
