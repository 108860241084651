export class ApplicantCardStatus {
    requestDttm: string;
    processedDttm: string;
    verificationDttm: string;
    approvedDttm: string;
    requestComment: string;
    processedComment: string;
    verificationComment: string;
    approvedComment: string;
    currentStatus: string;
    visitDttm: string;
    cardType: string;

    requestDateTime: string;
    processedDateTime: string;
    verificationDateTime: string;
    approvedDateTime: string;
    visitDateTime: string;
}