import { HeaderTitle } from './shared/constant/global-constants';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UsersComponent } from './components/users/users.component';
import { AdmitCardComponent } from './components/admit-card/admit-card.component';
import { MyRequestComponent } from './components/my-request/my-request.component';
import { MyIdCardComponent } from './components/my-id-card/my-id-card.component';
import { LostCardComponent } from './components/lost-card/lost-card.component';
import { RenewalCardComponent } from './components/renewal-card/renewal-card.component';
import { AuthModule } from './auth/auth.module';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: ()=> AuthModule
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | Dashboard' }
  },
  {
    path: 'about',
    component: AboutusComponent,
    data: { title: HeaderTitle + ' | AboutUs' }
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyComponent,
    data: { title: HeaderTitle + ' | Privacy Policy' }
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | User Detail' }
  },
  {
    path: 'admitcard',
    component: AdmitCardComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | ID Card' }
  },
  {
    path: 'renewalcard',
    component: RenewalCardComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | Renewal ID Card' }
  },
  {
    path: 'renewalcard/edit',
    component: RenewalCardComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | Renewal ID Card' }
  },
  {
    path: 'my-request',
    component: MyRequestComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | My Request' }
  },
  {
    path: 'my-card',
    component: MyIdCardComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | My Id Card' }
  },
  {
    path: 'lost-and-damage-card',
    component: LostCardComponent,
    canActivate: [AuthGuard],
    data: { title: HeaderTitle + ' | Apply for lost and damage id card' }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: HeaderTitle + ' | 404' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
