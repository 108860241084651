import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LostCardService {

  constructor(private _http: HttpClient) { }

  submitLostCardReq(mobileNo) {
    const formData = new FormData();
    formData.append('cardType', "3");
    formData.append('mobileNo', mobileNo);
    let url = environment.API_ENDPOINT_SECURE + "/applicantdetail/submit";
    return this._http.post(url, formData, { observe: 'response', headers: { 'No-Content-Type': '' } });
  }
}
