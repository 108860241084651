export const locale = {
	lang: 'gj',
	data: {
		NAME: "નામ",
		CARD_VALID_UPTO: "ઓળખપત્ર માન્યતા તારીખ",
		NATURE_OF_HANDICAPPED: "અપંગતા નો પ્રકાર",
		ENTER_MOBILE_NUMBER: "મોબાઇલ નંબર દાખલ કરો",
		DIDNOT_RECEIVE_THE_OTP: "OTP મળ્યો નથી?",
		SELECT_HOSPITAL_NAME: "હોસ્પિટલ નામ પસંદ કરો",
		REGISTER_MOBILE: "મોબાઈલ નંબર નાખો",
		AHMEDABAD_ADI: "અમદાવાદ (ADI)",
		NAME_OF_HOSPITAL: "હોસ્પિટલ નું નામ",
		SUBMIT: "મોકલવું",
		HOSPITAL_NAME: "હોસ્પિટલ નું નામ",
		DOCUMENTS: "દસ્તાવેજો",
		CHANGE_THE_LAUNGAGE: "ભાષા બદલો",
		AADHAR_CARD_NUMBER: "આધારકાર્ડ નંબર",
		TERMS_AND_CONDITION_APPLIED: "નિયમો અને શરતો લાગુ.",
		DONE: "પૂર્ણ",
		CANCEL: "રદ કરો",
		GENDER: "જાતિ",
		ISSUE_DATE: "ઇશ્યુની તારીખ",
		USER_NEED_TO_PAY_SOME_AMOUNT_FOR_DUPLICATE_CARD: "ડુપ્લિકેટ ઓળખપત્ર માટે વપરાશકર્તાએ થોડી રકમ ચૂકવવાની જરૂર છે.",
		STEP2: "પગલું ૨",
		UPLOAD_NEW_DOCUMENT: "નવો દસ્તાવેજ અપલોડ કરો",
		REGTD_NO_OF_DR: "ડૉક્ટર નો રજીસ્ટર નંબર ",
		NAME_OF_GOVDR: "સરકારી ડોક્ટરનું નામ",
		SELECT_HOSPITAL: "-હોસ્પિટલ પસંદ કરો-",
		SELECT_DIVISION: "-વિભાગ પસંદ કરો-",
		REGTD_NO_OF_DOCTOR: "ડૉક્ટર નો રજીસ્ટર નંબર ",
		DATE_OF_BIRTH: "જન્મ તારીખ",
		SKIP: "અવગણો",
		DIVYANG_APPLICATION: "દિવ્યાંગ એપ્લિકેશન",
		ENTER_OTP_HERE: "અહીં OTP દાખલ કરો !!",
		AADHAR_CARD: "આધારકાર્ડ",
		APPLY_FOR_LOST_DAMAGE_CARD: "ખોવાયેલા અને નુકસાન થયેલ ઓળખપત્ર માટે અરજી કરો",
		APPLICATION_STATUS: "એપ્લિકેશન ની સ્થિતિ",
		NEW_APPLICATION_STATUS: "ઓળખપત્ર એપ્લિકેશન ની સ્થિતિ",
		RENEWAL_APPLICATION_STATUS: "ફરી થી ઓળખપત્ર એપ્લિકેશન ની સ્થિતિ",
		LOST_APPLICATION_STATUS: "ખોવાયેલા અને નુકસાન થયેલ ઓળખપત્ર એપ્લિકેશન ની સ્થિતિ",
		WESTERN_RAILWAY: "પશ્ચિમ રેલ્વે",
		MY_PROFILE: "મારી પ્રોફાઈલ",
		SIGNATURE_OF_THE_ISSUING_AUTHORITY_WITH_DESIGNATION_AND_STAMP: "હોદ્દો અને સ્ટેમ્પ સાથે ઇશ્યુ કરનાર ઓથોરિટીની સહી.",
		HOW_TO_APPLY_FOR_CARD: "ઓળખપત્ર માટે કેવી રીતે અરજી કરવી",
		PERSONAL_DETAILS: "અંગત વિગતો",
		SEND_OTP: "OTP મોકલો",
		STEP_ONE: "પગલું  ૧",
		DIVISION: "વિભાગ:",
		IF_YOU_HAVE_LOST_YOUR_ID_CARD: "જો તમારું ઓળખપત્ર ખોવાઈ ગયુ  છે તો ડુપ્લિકેટ ઓળખપત્ર માટે વપરાશકર્તાએ થોડી રકમ ચૂકવવાની જરૂર છે.",
		ID_CARD_NO: "ઓળખપત્ર નંબર",
		RESEND_OTP: "OTP ફરીથી મોકલો",
		CONTACT_NUMBER: "સંપર્ક નંબર",
		VALIDITY_OF_CONCESSION_CERTIFICATE: "કન્સેશન સર્ટિફિકેટની માન્યતા",
		ZONE: "વિસ્તાર",
		UPLOAD_ANY_ONE_OF_BELOW: "નીચેની કોઈપણ અપલોડ કરો:",
		ID_CARD_FOR_DISABLE_PERSONS: "અક્ષમ વ્યક્તિઓ માટે પશ્ચિમ રેલ્વે ઓળખપત્ર",
		NOTIFICATION_LIST: "સૂચના સૂચિ",
		NOTIFICATION: "સૂચના",
		ABOUT_US: "આ એપ્લિકેશન વિશે",
		LOGOUT: "લૉગ આઉટ",
		MOBILE_NO: "મોબાઇલ નંબર:",
		MY_REQUEST: "મારી વિનંતી",
		MY_ID_CARD: "મારું ઓળખપત્ર",
		APPLY_FOR_ID_CARD: "ઓળખપત્ર માટે અરજી કરો",
		RENEWAL_ID_CARD: "ફરી થી ઓળખપત્ર માટે ની અરજી",
		LOST_DAMAGE_ID: "ખોવાયેલ / નુકસાન પામેલ ઓળખપત્ર માટે અરજી",
		INSTRUCTION: "સૂચના",
		CONSESSION_CERTIFICATE: "કન્સેશન સર્ટિફિકેટ",
		DISABILITY_CERTIFICATE: "અપંગતા નું પ્રમાણપત્ર",
		BIRTH_PROOF: "જન્મનો પુરાવો",
		ID_CARD: "ઓળખપત્ર",
		ADDRESS_PROOF: "સરનામાં નો પુરાવો",
		PHOTO: "ફોટો",
		APPROVED: "માન્ય",
		DOCUMENT_VERIFICATION: "દસ્તાવેજ ની ચકાસણી",
		PROCESSING: "પ્રક્રિયા",
		REQUEST_SUBMITTED: "વિનંતી સબમિટ કરી",
		RAILWAY_CONCESSION_CERTIFICATION: "રેલ્વે કન્સેશન સર્ટિફિકેટ",
		UPLOAD_PHOTO: "ફોટો અપલોડ કરો",
		LEAVING_CERTIFICATE: "સ્કૂલ નું પ્રમાણપત્ર",
		BIRTH_CERTIFICATE: "જન્મ નું પ્રમાણપત્ર",
		ELECTION_CARD: "ચૂંટણી કાર્ડ",
		PAN_CARD: "પાન કાર્ડ",
		DRIVING_LICENSE: "ગાડી ચલાવવાની પરવાનગી",
		MALE: "પુરુષ",
		FEMALE: "સ્ત્રી ",
		PREFER_NOT_TO_DISCLOSE: "જાહેર ન કરવાનું પસંદ કરો",
		DO_YOU_WANT_TO_LOGOUT: "શું તમે એપ્લિકેશન માંથી બહાર જવા માંગો છો? ",
		YES: "હા",
		NO: "ના",
		TERMS_AND_CONDITIONS: "નિયમો અને શરતો ",
		TERMS_AND_CONDITIONS_APPLIED: "લાગુ.",
		SELECT_LANGUAGE: "ભાષા પસંદ કરો",
		NAME_OF_DOCTOR: "ડોક્ટરનું નામ",
		CLOSE: "બંધ",
		APPLICANT_NAME: "અરજદારનું નામ",
		ID_CARD_RENEWAL: "ખોવાયેલા અને નુકસાન થયેલ ઓળખપત્ર માટે અરજી કરો",
		EDIT_ID_CARD: "ઓળખપત્ર માં સુધારો",
		FROM_GALLERY: "ગેલેરી માંથી",
		FROM_DOCUMENT: "દસ્તાવેજ માંથી",
		SELECT_OPTION: "વિકલ્પ પસંદ કરો",
		TAKE_PHOTO: "ફોટોગ્રાફ લો",
		CHOOSE_FROM_GALLERY: "ગેલેરીમાંથી પસંદ કરો",
		SETTINGS: "સેટિંગ્સ",
		ENTER_NAME: "નામ દાખલ કરો",
		SELECT_DATE_OF_BIRTH: "જન્મ તારીખ પસંદ કરો",
		SELECT_GENDER: "જાતિ પસંદ કરો",
		ENTER_ADHARCARD_NUMBER: "આધારકાર્ડ નંબર દાખલ કરો",
		ENTER_VALID_ADHARCARD_NUMBER: "માન્ય આધારકાર્ડ નંબર દાખલ કરો",
		SELECT_HOSPITALNAME: "હોસ્પિટલ નામ પસંદ કરો",
		ENTER_DOCTORNAME: "ડોક્ટરનું નામ દાખલ કરો",
		ENTER_HANDICAPPED_NATURE: "અપંગતા નો પ્રકાર દાખલ કરો",
		ENTER_REG_NO_OF_DOCTOR: "ડૉક્ટર નો રજીસ્ટર નંબર  દાખલ કરો",
		ENTER_DOCTOR_NAME: "ડોક્ટરનું નામ દાખલ કરો",
		ENTER_REGISTERED_DOCTOR_NUMBER: "ડૉક્ટર નો રજીસ્ટર નંબર  દાખલ કરો",
		ENTER_NATURE_OF_HANDICAPPED: "અપંગતા નો પ્રકાર દાખલ કરો ",
		ENTER_YOUR_NAME: "તમારું નામ દાખલ કરો",
		SELECT_YOUR_GENDER: "તમારું જાતિ પસંદ કરો",
		APPLY_FOR_NEW_ID_CARD: "નવા ઓળખપત્ર માટે અરજી કરો",
		ALL_FIELDS_ARE_REQUIRED: "કૃપા કરીને બધા જરૂરી દસ્તાવેજો અપલોડ કરો.",
		PLEASE_UPLOAD_ALL_NECESSARY_DOCUMENTS: "આવશ્યક દસ્તાવેજો અપલોડ કરો",
		UPLOAD_DOCUMENTS: "દસ્તાવેજો અપલોડ કરો",
		ENTER_VALID_MOBILE_NUMBER: "માન્ય મોબાઇલ નંબર દાખલ કરો",
		DIVYANG_APP: "દિવ્યાંગ એપ",
		SELECT_TERMS_AND_CONDICTION: "નિયમો અને શરતો પસંદ કરો",
		PLEASE_ENTER_OTP: "મહેરબાની કરીને OTP દાખલ કરો",
		PLEASE_ENTER_CORRECT_OTP: "OTP આવશ્યક છે અથવા ફક્ત OTP માટે સંખ્યાઓનો ઉપયોગ કરો",
		PLEASE_SELECT_THE_HOSPITAL: "કૃપા કરીને હોસ્પિટલ પસંદ કરો",
		PLEASE_SELECT_THE_DIVISION: "કૃપા કરીને વિભાગ પસંદ કરો",
		EDIT: "ફેરફાર કરો",
		BACK: "પાછળ",
		UPLOAD_RAILWAY_CONCESSION_CERTIFICATE: "રેલ્વે કન્સેશન સર્ટિફિકેટ અપલોડ કરો",
		UPLOAD_DISABILITY_CERTIFICATE: "અપંગતા નું પ્રમાણપત્ર અપલોડ કરો",
		UPLOAD_BIRTH_PROOF: "જન્મનો પુરાવો અપલોડ કરો",
		UPLOAD_ID_CARD: "ઓળખપત્ર અપલોડ કરો",
		UPLOAD_ADDRESS_PROOF: "સરનામાં નો પુરાવો અપલોડ કરો",
		PLEASE_SELECT_THE_MOBILE_NO: "મહેરબાની કરીને મોબાઇલ નંબર પસંદ કરો",
		ESCORT: "અનુરક્ષક",
		EXTENTION_NOT_ALLOWED: "ફાઇલ એક્સ્ટેંશનને મંજૂરી નથી. ફક્ત છબી અથવા પીડીએફ પ્રકારની ફાઇલ અપલોડ કરો.",
		MAX_FILE_ALLOWED: "મહત્તમ 3 ફાઇલોને મંજૂરી છે.",
		MAX_FILE_SIZE_ALLOWED: "ફાઇલ કદ 1MB કરતા ઓછું હોવું જોઈએ.",
		UPLOAD_ANY_ONE: "નિચેના માથી કોઈપણ એક અપલોડ કરો.",
		ONE: "૧.",
		TWO: "૨.",
		THREE: "૩.",

		PLEASE_ENTER_NAME: "hello क्रूपा करि ने तमारु नाम दाखल करो",
		PLEASE_SELECT_DATE_OF_BIRTH: "hello क्रूपा करि ने तमारि जन्म तिथि दाखल करो",
		PLEASE_SELECT_GENDER: "hello क्रूपा करि ने तमारि जाति पसन्द करो",
		PLEASE_ENTER_MOBILE_NO: "hello क्रूपा करि ने तमारो मोबाइल नंबर दाखल करो",
		PLEASE_ENTER_ADHARCARD_NUMBER: "hello क्रूपा करि ने तमारो आधार कार्ड नंबर दाखल करो",
		PLEASE_SELECT_HOSPITAL_NAME: "hello क्रूपा करि ने होस्पिटल नु नाम दाखल करो",
		PLEASE_ENTER_DOCTORNAME: "hello क्रूपा करि ने दोक्टर नु नाम दाखल करो",
		PLEASE_ENTER_REGISTERED_DOCTOR_NUMBER: "hello क्रूपा करि ने रजिस्टर दोक्टर नंबर दाखल करो",
		PLEASE_ENTER_HANDICAPPED_NATURE: "hello क्रूपा करि ने अपंगता नो प्रकार दाखल करो",
		PLEASE_UPLOAD_RAILWAY_CONCESSION_CERTIFICATE: "hello क्रूपा करि ने रेलवे कन्सेशन सर्तिफ़िकेट अपलोड करो.",
		PLEASE_UPLOAD_DISABILITY_CERTIFICATE: "hello क्रूपा करि ने अपंगता नु प्रमाणपत्र अपलोड करो",
		PLEASE_UPLOAD_BIRTH_PROOF: "hello क्रूपा करि ने  जन्म नो पुरावो अपलोड करो",
		PLEASE_UPLOAD_ID_CARD: "hello क्रूपा करि ने ओळखपत्र अपलोड करो",
		PLEASE_UPLOAD_ADDRESS_PROOF: "hello क्रूपा करि ने सरनामा नो पुरावो अपलोड करो",
		PLEASE_UPLOAD_PHOTO: "hello क्रूपा करि ने फोटो अपलोड करो",

		HEADER_HOME: "ઘર",
		HEADER_ABOUT: "વિશે",
		HEADER_FEATURES: "વિશેષતા",
		HEADER_DOWNLOAD: "ડાઉનલોડ કરો",
		HEADER_CONTACT_US: "અમારો સંપર્ક કરો",
		HEADER_DOWNLOAD_DOCUMENT: "દસ્તાવેજ ડાઉનલોડ કરો",
		HEADER_TERMS: "નિયમો અને શરતો",

		HEADER_Privacy_Policy:"ગોપનીયતા નીતિ", 
		

		ENTER_VALID_DOCTOR_NAME: "માન્ય ડોક્ટર નામ દાખલ કરો.",
		ENTER_VALID_HANDICAPPED_NATURE: "માન્ય વિકલાંગ પ્રકૃતિ દાખલ કરો.",
		ENTER_VALID_NAME: "માન્ય નામ દાખલ કરો.",
		EXTENSION_NOTE: "નોંધ: '.jpg, .png, .pdf ' એક્સ્ટેંશનની ફાઇલો ફક્ત અપલોડ કરી શકાય છે.",

		DIVISION_NAME: "વિભાગ નામ"


	}
};
