import { Component, OnInit } from '@angular/core';
import { MyIdCardService } from '../../services/my-id-card/my-id-card.service';
import { ToastrService } from 'ngx-toastr';
import { MyIdCard } from '../../models/my-id-card'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-my-id-card',
  templateUrl: './my-id-card.component.html',
  styleUrls: ['./my-id-card.component.scss']
})
export class MyIdCardComponent implements OnInit {
  getMobileNo: any;
  public myIdCard: MyIdCard;
  myIdCardForm: FormGroup;

  constructor(
    private _myIdCardService: MyIdCardService,
    private _toastrService: ToastrService,
    private _formBuilder: FormBuilder,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.myIdCard = new MyIdCard()
    this.getMyIdCardDetails();

    this.myIdCardForm = this._formBuilder.group({
      generatedCardNo: ['', Validators.required],
      name: ['', Validators.required],
      gender: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      issueDttm: ['', Validators.required],
      dueDttm: ['', Validators.required],
      doctorName: ['', Validators.required],
      divisionName: ['', Validators.required],
      hospitalName: ['', Validators.required],
      doctorRegdNo: ['', Validators.required],
      concessionCertiValidity: ['', Validators.required],
      handicappedNature: ['', Validators.required],
      escort: ['', Validators.required]
    })
  }
  getMyIdCardDetails() {
    this.getMobileNo = localStorage.getItem('mobileNo');
    this._myIdCardService.getApplicantCardDetail(this.getMobileNo).subscribe(res => {
      if (res.status == 200) {
        this.myIdCard = res.body['result']['applicantDetail'];

        this.myIdCard.dueDttm = formatDate(this.myIdCard.dueDttm, 'dd/MM/yyyy', 'en');
        this.myIdCard.issueDttm = formatDate(this.myIdCard.issueDttm, 'dd/MM/yyyy', 'en');
        this.myIdCard.dateOfBirth = formatDate(this.myIdCard.dateOfBirth, 'dd/MM/yyyy', 'en');
        if (this.myIdCard.concessionCertiValidity) {
          this.myIdCard.concessionCertiValidity = formatDate(this.myIdCard.concessionCertiValidity, 'dd/MM/yyyy', 'en');
        }
        if (this.myIdCard.photo != null && this.myIdCard.photo.length > 0) {
          this.getApplicantDocuments(this.myIdCard.photo[0]);
        }

      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })
  }

  getApplicantDocuments(filename) {
    let data;

    this._myIdCardService.getApplicantPhoto(filename).subscribe(res => {
      if (res && res.body && res.body["result"] && res.body["result"]["image"]) {
        data = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/' + filename.split(".").pop() + ';base64,' + res.body["result"]["image"]);
        this.myIdCard.photo = data;
      }
    });
  }

  // openPdf() {
  //   let mobileNo = localStorage.getItem('mobileNo');
  //   this._myIdCardService.geteSignPdfDetails(mobileNo).subscribe((res: any) => {
  //     if (res && res['statusCode'] == 200 && res['result']["image"]) {
  //       const binaryString = window.atob(res['result']["image"]);
  //       const len = binaryString.length;
  //       const bytes = new Uint8Array(len);
  //       for (let i = 0; i < len; ++i) {
  //         bytes[i] = binaryString.charCodeAt(i);
  //       }
  //       let blob = new Blob([bytes], { type: "application/pdf" })
  //       window.open(URL.createObjectURL(blob))
  //     } else {
  //       let message = res['message'] ? res['message'] : "File Not Found";
  //       this._toastrService.error("", message);
  //     }
  //   }, err => {
  //     this._toastrService.error("", "Some error occured");
  //   })
  // }

}
