import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './../../services/common/common.service';
import { regExp } from './../../shared/constant/global-constants';
import { PostService } from './../../services/post/post.service';
import * as moment from 'moment';
import Speech from "speak-tts";
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { NgxImageCompressService } from 'ngx-image-compress';


@Component({
  selector: 'app-renewal-card',
  templateUrl: './renewal-card.component.html',
  styleUrls: ['./renewal-card.component.scss']
})
export class RenewalCardComponent implements OnInit {

  BirthProofChoices: string[] = [
    'Birth Certificate',
    'Leaving Certificate'
  ];
  IdcardChoices: string[] = [
    'Election Card',
    'Aadhar Card',
    'Pan Card'
  ];
  AddressChoices: string[] = [
    'Election Card',
    'Aadhar Card',
    'Driving Licence'
  ];
  hospital: Array<any> = [];
  divisions = [];
  renewalcardForm: FormGroup;
  submitted: boolean = false
  maxDate: Date;

  railwayConcessionCertificate: any = [];
  disabilityCertificate: any = [];
  birthProof: any = [];
  idCard: any = [];
  addressProof: any = [];
  photo: any = [];

  railwayConcessionCertificateIMG: string = "assets/images/concession_certification.png";
  disabilityCertificateIMG: string = "assets/images/disability_certificate.png";
  birthProofIMG: string = "assets/images/birth_proof.png";
  idCardIMG: string = "assets/images/id-card.png";
  addressProofIMG: string = "assets/images/address_proof.png";
  photoIMG: string = "assets/images/photo.png";

  railwayConcessionCertificateLength: number = 0;
  disabilityCertificateLength: number = 0;
  birthProofLength: number = 0;
  idCardLength: number = 0;
  addressProofLength: number = 0;
  photoLength: number = 0;

  idCardselect: string = '';
  addressProofselect: string = '';
  fileInputCheck: any = {
    birthProof: false,
    idCard: false,
    addressProof: false
  }
  getMobileNo: any;
  getDivisionId: number = 0;
  IDCard: any;
  editIDCard: boolean = false;
  _albums: Array<any> = [];
  isEditMode: boolean = false;

  constructor(private _commonService: CommonService,
    private _postService: PostService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastrService: ToastrService,
    public translate: TranslateService,
    private _lightbox: Lightbox,
    private imageCompress: NgxImageCompressService
  ) {


  }

  ngOnInit() {
    this.maxDate = new Date();

    if (this._router.url.includes('edit')) {
      this.editIDCard = true;
    }


    this.getMobileNo = localStorage.getItem('mobileNo');
    this._commonService.getApplicantCardDetail(this.getMobileNo).subscribe(data => {
      if (data.status && data.body && data.body['result'] && data.body['result']['applicantDetail']) {
        let res = data.body['result']['applicantDetail'];
        // this.getHospital();
        this.getAllDevisions();
        this.getDivisionId = Number(localStorage.getItem('divisionId'));
        this.hospitalByDivisionId(this.getDivisionId);
        this.IDCard = res;

        if (this.IDCard.mobileNo == null) {
          // localStorage.setItem("cardType", '3');
          this._router.navigate(['/admitcard']);
          return;
        }

        if (this.IDCard.dateOfBirth) {
          this.IDCard.dateOfBirth = moment(this.IDCard.dateOfBirth).format('DD/MM/YYYY')
        } else {
          this.IDCard.dateOfBirth = '';
        }
        this.railwayConcessionCertificateLength = this.IDCard.railwayConcessionCertificate.length ? this.IDCard.railwayConcessionCertificate.length : 0;
        this.disabilityCertificateLength = this.IDCard.disabilityCertificate.length ? this.IDCard.disabilityCertificate.length : 0;
        this.birthProofLength = this.IDCard.birthProof.length ? this.IDCard.birthProof.length : 0;
        this.idCardLength = this.IDCard.idCard.length ? this.IDCard.idCard.length : 0;
        this.addressProofLength = this.IDCard.addressProof.length ? this.IDCard.addressProof.length : 0;
        this.photoLength = this.IDCard.photo.length ? this.IDCard.photo.length : 0;

        this.railwayConcessionCertificateIMG = "assets/images/concession_certification_upload.png";
        this.disabilityCertificateIMG = "assets/images/concession_certification_upload.png";
        this.birthProofIMG = "assets/images/birth_proof_upload.png";
        this.idCardIMG = "assets/images/id-card_upload.png";
        this.addressProofIMG = "assets/images/address_proof_upload.png";
        this.photoIMG = "assets/images/photo_uploaded.png";

        if (this.editIDCard) {
          this.initForm();
        }
      } else {
        if (this.editIDCard) {
          this.initForm();
        }
      }
    });

  }

  initForm() {

    this.renewalcardForm = this._formBuilder.group({
      name: [{ value: this.IDCard.name, disabled: true }, Validators.required],
      dateOfBirth: [{ value: this.IDCard.dateOfBirth, disabled: true }, Validators.required],
      gender: [{ value: this.IDCard.gender, disabled: true }, Validators.required],
      cardType: [2],
      mobileNo: [{ value: this.IDCard.mobileNo, disabled: true }, Validators.compose([
        //Validators.required,
        Validators.pattern(regExp.numRegEx),
        Validators.minLength(10),
        Validators.maxLength(10)
      ])],
      aadharCard: [{ value: this.IDCard.aadharCard, disabled: true }, Validators.compose([
        Validators.required,
        Validators.pattern(regExp.numRegEx),
        Validators.minLength(12),
        Validators.maxLength(12)
      ])],
      divisionId: [this.IDCard.divisionId, Validators.required],
      hospitalId: [this.IDCard.hospitalId, Validators.required],
      doctorName: [this.IDCard.doctorName,
      Validators.compose(
        [
          Validators.required,
          Validators.pattern(regExp.nameRegEx),
          Validators.minLength(3),
          Validators.maxLength(100),
        ]
      )
      ],
      doctorRegdNo: [this.IDCard.doctorRegdNo, Validators.required],
      handicappedNature: [this.IDCard.handicappedNature, Validators.compose([
        Validators.required,
        Validators.pattern(regExp.nameRegEx),
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ],
      railwayConcessionCertificate: [''],
      disabilityCertificate: [''],
      birthProof: [''],
      idCard: [''],
      addressProof: [''],
      photo: [''],
      isEditMode: [true]

    })
    //this.changeDetectorRef.markForCheck();
  }



  onSubmit() {
    this.submitted = true

    // stop here if form is invalid
    if (this.renewalcardForm.invalid) {
      return;
    }

    const renewalcardFormValue = this.prepareData();
    if (this.renewalcardForm.valid) {
      this._postService.post_form('/applicantdetail/submit', renewalcardFormValue.form).subscribe(res => {
        if (res.status === 200) {
          localStorage.setItem("hospitalId", this.renewalcardForm.controls.hospitalId.value);
          localStorage.setItem("divisionId", this.renewalcardForm.controls.divisionId.value);
          this._toastrService.success(res.body['message'], 'Success')
          this._router.navigate(['/'])
        }
      }, error => {
        var fieldError = ""
        if (error['error'].data) {
          fieldError = error['error'].data
        } else {
          fieldError = "Something went wrong, please try again or after sometime."
        }
        this._toastrService.error(fieldError, 'Error')
      })
    }
  }

  prepareData(): any {
    const controls = this.renewalcardForm.controls;
    const _admin_card: any = {};

    let dt = controls['dateOfBirth'].value;
    let dtSend = moment(dt).format('YYYY-MM-DD HH:mm:ss');
    _admin_card.name = controls['name'].value;
    _admin_card.dateOfBirth = dtSend;
    _admin_card.cardType = 2;
    _admin_card.gender = controls['gender'].value;
    _admin_card.mobileNo = controls['mobileNo'].value;
    _admin_card.aadharCard = controls['aadharCard'].value;
    _admin_card.hospitalId = controls['hospitalId'].value;
    _admin_card.divisionId = controls['divisionId'].value;
    _admin_card.doctorName = controls['doctorName'].value;
    _admin_card.doctorRegdNo = controls['doctorRegdNo'].value;
    _admin_card.handicappedNature = controls['handicappedNature'].value;
    _admin_card.isEditMode = this.IDCard.generatedCardNo === null ? true : false;

    const formData = new FormData();
    Object.keys(_admin_card).forEach(item => {
      formData.append(item, _admin_card[item]);
    })

    if (this.railwayConcessionCertificate != null && this.railwayConcessionCertificate != '') {
      for (let i = 0; i < this.railwayConcessionCertificate.length; i++) {
        formData.append("railwayConcessionCertificate", this.railwayConcessionCertificate[i]);
      }
    }
    if (this.disabilityCertificate != null && this.disabilityCertificate != '') {
      for (let i = 0; i < this.disabilityCertificate.length; i++) {
        formData.append("disabilityCertificate", this.disabilityCertificate[i]);
      }
    }
    if (this.birthProof != null && this.birthProof != '') {
      for (let i = 0; i < this.birthProof.length; i++) {
        formData.append("birthProof", this.birthProof[i]);
      }

    }
    if (this.idCard != null && this.idCard != '') {
      for (let i = 0; i < this.idCard.length; i++) {
        formData.append("idCard", this.idCard[i]);
      }

    }
    if (this.addressProof != null && this.addressProof != '') {
      for (let i = 0; i < this.addressProof.length; i++) {
        formData.append("addressProof", this.addressProof[i]);
      }

    }
    if (this.photo != null && this.photo != '') {
      for (let i = 0; i < this.photo.length; i++) {
        formData.append("photo", this.photo[i]);
      }
    }

    return { form: formData, _admin_card: _admin_card };
  }

  onPreventClick(event: MouseEvent, item: string) {
    if (!this.fileInputCheck[item]) {
      event.preventDefault();
    }
  }

  enableValue(controlName: string, value: string) {
    this.fileInputCheck[controlName] = true;
    this.renewalcardForm.controls[controlName].setValue('');
    if (controlName == 'idCard') {
      this.idCardselect = value;
      if (this.idCardselect == this.addressProofselect) {
        if (this.addressProof) {
          this.idCardIMG = "assets/images/id-card_upload.png";
          this.idCard = this.addressProof;
          this.idCardLength = this.addressProofLength;
          this.renewalcardForm.controls['idCard'].setErrors(null);
        }
      } else {
        this.idCardIMG = "assets/images/id-card.png";
      }
    }
    if (controlName == 'addressProof') {
      this.addressProofselect = value;
      if (this.idCardselect == this.addressProofselect) {
        if (this.idCard) {
          this.addressProofIMG = "assets/images/address_proof_upload.png";
          this.addressProof = this.idCard;
          this.addressProofLength = this.idCardLength;
          this.renewalcardForm.controls['addressProof'].setErrors(null);
        }
      } else {
        this.addressProofIMG = "assets/images/address_proof.png";
      }
    }

  }

  onFileSelected(event, controlName: string) {

    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      var filesLength = event.target.files.length;


      if (controlName == 'idCard') {
        this.idCard = [];
      }
      if (controlName == 'addressProof') {
        this.addressProof = [];
      }

      //max file upload validation
      if (filesLength > 3) {
        this.renewalcardForm.controls[controlName].setErrors({ 'maxallowedFile': true });
        return;
      }

      for (let i = 0; i < filesLength; i++) {
        var fileSize = 0;
        var newImagefile;
        //file extention  validation
        const allowedFileExt = ['jpg', 'jpeg', 'png', 'HEIC', 'heic', 'pdf'];
        var ext = event.target.files[i].name.substr(event.target.files[i].name.lastIndexOf('.') + 1).toLowerCase();
        if (ext == '') return;
        if (allowedFileExt.indexOf(ext) == -1) {
          if (controlName == 'railwayConcessionCertificate') {
            this.railwayConcessionCertificateLength = 0;
            this.railwayConcessionCertificate = [];
            this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
          }
          if (controlName == 'disabilityCertificate') {
            this.disabilityCertificateLength = 0;
            this.disabilityCertificate = [];
            this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
          }
          if (controlName == 'birthProof') {
            this.birthProofLength = 0;
            this.birthProof = [];
            this.birthProofIMG = "assets/images/birth_proof.png";
          }
          if (controlName == 'idCard') {
            this.idCardLength = 0;
            this.idCard = [];
            this.idCardIMG = "assets/images/id-card.png";
          }
          if (controlName == 'addressProof') {
            this.addressProofLength = 0;
            this.addressProof = [];
            this.addressProofIMG = "assets/images/address_proof.png";
          }
          if (controlName == 'photo') {
            this.photoLength = 0;
            this.photo = [];
            this.photoIMG = "assets/images/photo.png";
          }
          this.renewalcardForm.controls[controlName].setErrors({ 'allowedFileExt': true });
          return;
        }

        if (ext == 'pdf') {
          if (event.target.files[i].size >= 1000000) {
            if (controlName == 'railwayConcessionCertificate') {
              this.railwayConcessionCertificateLength = 0;
              this.railwayConcessionCertificate = [];
              this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
            }
            if (controlName == 'disabilityCertificate') {
              this.disabilityCertificateLength = 0;
              this.disabilityCertificate = [];
              this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
            }
            if (controlName == 'birthProof') {
              this.birthProofLength = 0;
              this.birthProof = [];
              this.birthProofIMG = "assets/images/birth_proof.png";
            }
            if (controlName == 'idCard') {
              this.idCardLength = 0;
              this.idCard = [];
              this.idCardIMG = "assets/images/id-card.png";
            }
            if (controlName == 'addressProof') {
              this.addressProofLength = 0;
              this.addressProof = [];
              this.addressProofIMG = "assets/images/address_proof.png";
            }
            if (controlName == 'photo') {
              this.photoLength = 0;
              this.photo = [];
              this.photoIMG = "assets/images/photo.png";
            }
            this.renewalcardForm.controls[controlName].setErrors({ 'maxallowedFileSize': true });
            return;
          }

          if (controlName == 'railwayConcessionCertificate') {
            this.railwayConcessionCertificateLength = filesLength;
            this.railwayConcessionCertificate.push(event.target.files[i]);
            this.railwayConcessionCertificateIMG = "assets/images/concession_certification_upload.png";
          }
          if (controlName == 'disabilityCertificate') {
            this.disabilityCertificateLength = filesLength;
            this.disabilityCertificate.push(event.target.files[i]);
            this.disabilityCertificateIMG = "assets/images/concession_certification_upload.png";
          }
          if (controlName == 'birthProof') {
            this.birthProofLength = filesLength;
            this.birthProof.push(event.target.files[i]);
            this.birthProofIMG = "assets/images/birth_proof_upload.png";
          }
          if (controlName == 'idCard') {
            this.idCardLength = filesLength;
            this.idCard.push(event.target.files[i]);
            this.idCardIMG = "assets/images/id-card_upload.png";
          }
          if (controlName == 'addressProof') {
            this.addressProofLength = filesLength;
            this.addressProof.push(event.target.files[i]);
            this.addressProofIMG = "assets/images/address_proof_upload.png";
          }
          if (controlName == 'photo') {
            this.photoLength = filesLength;
            this.photo.push(event.target.files[i]);
            this.photoIMG = "assets/images/photo_uploaded.png";
          }

        }
        else {
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = (_event) => {
            this.imageCompress.compressFile(reader.result, -1, 75, 50).then(
              result => {
                fileSize = this.imageCompress.byteCount(result);
                newImagefile = new File([result], event.target.files[i].name);
                // max 1mb  file size validation
                if (fileSize >= 1000000) {
                  if (controlName == 'railwayConcessionCertificate') {
                    this.railwayConcessionCertificateLength = 0;
                    this.railwayConcessionCertificate = [];
                    this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
                  }
                  if (controlName == 'disabilityCertificate') {
                    this.disabilityCertificateLength = 0;
                    this.disabilityCertificate = [];
                    this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
                  }
                  if (controlName == 'birthProof') {
                    this.birthProofLength = 0;
                    this.birthProof = [];
                    this.birthProofIMG = "assets/images/birth_proof.png";
                  }
                  if (controlName == 'idCard') {
                    this.idCardLength = 0;
                    this.idCard = [];
                    this.idCardIMG = "assets/images/id-card.png";
                  }
                  if (controlName == 'addressProof') {
                    this.addressProofLength = 0;
                    this.addressProof = [];
                    this.addressProofIMG = "assets/images/address_proof.png";
                  }
                  if (controlName == 'photo') {
                    this.photoLength = 0;
                    this.photo = [];
                    this.photoIMG = "assets/images/photo.png";
                  }
                  this.renewalcardForm.controls[controlName].setErrors({ 'maxallowedFileSize': true });
                  return;
                }

                if (controlName == 'railwayConcessionCertificate') {
                  this.railwayConcessionCertificateLength = filesLength;
                  this.railwayConcessionCertificate.push(newImagefile);
                  this.railwayConcessionCertificateIMG = "assets/images/concession_certification_upload.png";
                }
                if (controlName == 'disabilityCertificate') {
                  this.disabilityCertificateLength = filesLength;
                  this.disabilityCertificate.push(newImagefile);
                  this.disabilityCertificateIMG = "assets/images/concession_certification_upload.png";
                }
                if (controlName == 'birthProof') {
                  this.birthProofLength = filesLength;
                  this.birthProof.push(newImagefile);
                  this.birthProofIMG = "assets/images/birth_proof_upload.png";
                }
                if (controlName == 'idCard') {
                  this.idCardLength = filesLength;
                  this.idCard.push(newImagefile);
                  this.idCardIMG = "assets/images/id-card_upload.png";
                }
                if (controlName == 'addressProof') {
                  this.addressProofLength = filesLength;
                  this.addressProof.push(newImagefile);
                  this.addressProofIMG = "assets/images/address_proof_upload.png";
                }
                if (controlName == 'photo') {
                  this.photoLength = filesLength;
                  this.photo.push(newImagefile);
                  this.photoIMG = "assets/images/photo_uploaded.png";
                }
              }
            );
          }
        }

      }

    }

  }

  getHospital() {
    this._commonService.get('/hospitals').subscribe(data => {
      if (data.status == 200) {
        this.hospital = []
        data.body['result'].hospitals.forEach((ele, index) => {
          this.hospital.push(ele)
        })
      }
    }, error => {
      console.log(error)
    })
  }
  /**
  * get hospital by division
  */
  hospitalByDivisionId(divisionId) {
    this._commonService.get("/hospitalbydivisionid?divisionId=" + divisionId).subscribe((data) => {
      if (data) {
        this.hospital = []
        data.body['result'].hospitals.forEach((ele, index) => {
          this.hospital.push(ele)
        })
      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })

  }
  getAllDevisions() {
    this._commonService.getSecure('/divisionlist').subscribe((res: any) => {
      if (res) {
        this.divisions = res;
      }
    })
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    if (this.renewalcardForm.controls[controlName].value != null) {
      let data = this.renewalcardForm.controls[controlName].value;
      try {
        data = data != null ? data.trim() : null;
        if (!data || !data.length || data.length == 0) {
          this.renewalcardForm.controls[controlName].setValue(this.renewalcardForm.controls[controlName].value.trim());
        }
      } catch (error) {

      }
    }
    const control = this.renewalcardForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType);
    return result;
  }

  textToSpeach(textspeach: string) {
    textspeach = this.translate.instant(textspeach);
    const speech = new Speech();
    speech
      .init({
        volume: 0.5,
        lang: "hi-IN",
        rate: 1,
        pitch: 1,

      })
      .then(data => {
        speech.speak({
          text: textspeach,
          queue: false,
        })
          .then(data => {
            //console.log("Success !", data);
          })
          .catch(e => {
            console.error("An error occurred :", e);
          });
      })
      .catch(e => {
        console.error("An error occured while initializing : ", e);
      });
  }
  open(imageList) {
    this._albums = [];
    if (imageList.length > 0) {
      for (let i = 0; i < imageList.length; i++) {
        var ext = imageList[i].substr(imageList[i].lastIndexOf('.') + 1);
        if (ext) {
          var imagetype = 'data:image/jpg;base64,';
          if (ext == 'pdf') {
            imagetype = 'data:application/pdf;base64,';
          } else if (ext != '') {
            imagetype = 'data:image/' + ext + ';base64,';
          }

          this._postService.get_secure('/applicantdocument?filename=' + imageList[i]).subscribe(data => {
            if (data['status'] == 200) {
              let imsgesrc = imagetype + data['body']['result'].image;
              this._albums.push({
                src: imsgesrc,
                caption: "",
                thumb: imsgesrc
              });

              if (ext == 'pdf') {
                //var win = window.open();
                //win.document.write('<iframe src="' + imsgesrc + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                const downloadLink = document.createElement("a");
                const fileName = imageList[i];

                downloadLink.href = imsgesrc;
                downloadLink.download = fileName;
                downloadLink.click();
              }
            }
          }, error => {
            console.log(error)
          })
        }

      }
      if (this._albums) {
        if (ext == 'pdf' && ext != '') {
          //var win = window.open();
          //win.document.write('<iframe src="' + imsgesrc + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        } else if (ext != '') {
          this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true });

        } else {
          this._toastrService.error("Document Not Found", 'Error')
        }

      }
    }

  }

  close(): void {
    this._lightbox.close();
  }
}
