export const locale = {
	lang: 'en',
	data: {
		NAME: "Name",
		CARD_VALID_UPTO: "Card Valid upto",
		NATURE_OF_HANDICAPPED: "Nature of Handicapped",
		ENTER_MOBILE_NUMBER: "Enter Mobile Number",
		DIDNOT_RECEIVE_THE_OTP: "Didn't receive the OTP?",
		SELECT_HOSPITAL_NAME: "Select Hospital Name",
		REGISTER_MOBILE: "Register Mobile",
		AHMEDABAD_ADI: "Ahmedabad(ADI)",
		NAME_OF_HOSPITAL: "Name of Hospital",
		SUBMIT: "Submit",
		HOSPITAL_NAME: "Hospital Name",
		DOCUMENTS: "Documents",
		CHANGE_THE_LAUNGAGE: "Change the Laungage",
		AADHAR_CARD_NUMBER: "Aadhar Card Number",
		TERMS_AND_CONDITION_APPLIED: "Terms & Condition Applied.",
		DONE: "Done",
		CANCEL: "Cancel",
		GENDER: "Gender",
		SELECT_GENDER: "Select Gender",
		ISSUE_DATE: "Issue Date",
		USER_NEED_TO_PAY_SOME_AMOUNT_FOR_DUPLICATE_CARD: "User need to pay some amount for duplicate card.",
		STEP2: "STEP2",
		UPLOAD_NEW_DOCUMENT: "Upload New Document",
		REGTD_NO_OF_DR: "Regtd no. of Dr.",
		NAME_OF_GOVDR: "Name of Gov.Dr.",
		SELECT_HOSPITAL: "-Select Hospital-",
		SELECT_DIVISION: "-Select Division-",
		REGTD_NO_OF_DOCTOR: "Regtd no. of Doctor",
		DATE_OF_BIRTH: "Date of Birth",
		SKIP: "Skip",
		DIVYANG_APPLICATION: "Divyang Application",
		ENTER_OTP_HERE: "Enter OTP here!!",
		AADHAR_CARD: "Aadhar Card",
		APPLY_FOR_LOST_DAMAGE_CARD: "Apply for lost & damage card",
		APPLICATION_STATUS: "Application Status",
		NEW_APPLICATION_STATUS: "Application Status of New Card",
		RENEWAL_APPLICATION_STATUS: "Application Status of Renewal Card",
		LOST_APPLICATION_STATUS: "Application Status of Damaged Card",
		WESTERN_RAILWAY: "Western Railway",
		MY_PROFILE: "My Profile",
		SIGNATURE_OF_THE_ISSUING_AUTHORITY_WITH_DESIGNATION_AND_STAMP: "Signature of the issuing Authority with Designation and stamp.",
		HOW_TO_APPLY_FOR_CARD: "How to apply for card",
		PERSONAL_DETAILS: "Personal Details",
		SEND_OTP: "Send OTP",
		STEP_ONE: "Step1",
		DIVISION: "Division:",
		IF_YOU_HAVE_LOST_YOUR_ID_CARD: "If you have lost your ID Card. User need to pay some amount for duplicate card.",
		ID_CARD_NO: "ID Card No",
		RESEND_OTP: "Resend OTP",
		CONTACT_NUMBER: "Contact Number",
		VALIDITY_OF_CONCESSION_CERTIFICATE: "Validity of Concession Certificate",
		ZONE: "Zone:",
		UPLOAD_ANY_ONE_OF_BELOW: "Upload any one of below:",
		ID_CARD_FOR_DISABLE_PERSONS: "Western Railway ID Card for Disable Persons",
		NOTIFICATION_LIST: "Notification List",
		NOTIFICATION: "Notification",
		ABOUT_US: "About Us",
		LOGOUT: "Logout",
		MOBILE_NO: "Mobile No:",
		MY_REQUEST: "Track Request",
		MY_ID_CARD: "My ID Card",
		APPLY_FOR_ID_CARD: "Apply for ID Card",
		RENEWAL_ID_CARD: "Renew Card",
		LOST_DAMAGE_ID: "Lost / Damage ID",
		INSTRUCTION: "Instruction",
		CONSESSION_CERTIFICATE: "Consession Certificate",
		DISABILITY_CERTIFICATE: "Disability Certificate",
		BIRTH_PROOF: "Birth Proof",
		ID_CARD: "ID Card",
		ADDRESS_PROOF: "Address Proof",
		PHOTO: "Photo",
		APPROVED: "Approved",
		DOCUMENT_VERIFICATION: "Document Verification",
		PROCESSING: "Processing",
		REQUEST_SUBMITTED: "Request Submitted",
		RAILWAY_CONCESSION_CERTIFICATION: "Railway Concession Certificate ",
		UPLOAD_PHOTO: "Upload Photo",
		LEAVING_CERTIFICATE: "Leaving Certificate",
		BIRTH_CERTIFICATE: "Birth Certificate",
		ELECTION_CARD: "Election Card",
		PAN_CARD: "Pan Card",
		DRIVING_LICENSE: "Driving License",
		MALE: "Male",
		FEMALE: "Female",
		PREFER_NOT_TO_DISCLOSE: "Prefer not to disclose",
		DO_YOU_WANT_TO_LOGOUT: "Do you want to Logout?",
		YES: "Yes",
		NO: "No",

		TERMS_AND_CONDITIONS: "Terms & Conditions ",
		TERMS_AND_CONDITIONS_APPLIED: "Applied.",
		SELECT_LANGUAGE: "Select Language",
		NAME_OF_DOCTOR: "Name of Doctor",
		CLOSE: "Close",
		APPLICANT_NAME: "Applicant Name",
		ID_CARD_RENEWAL: "ID Card Renewal",
		EDIT_ID_CARD: "Edit ID Card",
		FROM_GALLERY: "From Gallery",
		FROM_DOCUMENT: "From Document",
		SELECT_OPTION: "Select Option",
		TAKE_PHOTO: "Take Photo",
		CHOOSE_FROM_GALLERY: "Choose from Gallery",
		SETTINGS: "Settings",
		ENTER_NAME: "Enter Name",
		SELECT_DATE_OF_BIRTH: "Select Date of Birth",
		// SELECT_GENDER:"Select Gender",
		ENTER_ADHARCARD_NUMBER: "Enter Aadhar Card Number",
		ENTER_VALID_ADHARCARD_NUMBER: "Enter valid Aadhar Card Number.",
		SELECT_HOSPITALNAME: "Select HospitalName",
		ENTER_DOCTORNAME: "Enter Doctor Name.",
		ENTER_HANDICAPPED_NATURE: "Enter Handicapped Nature.",
		ENTER_REG_NO_OF_DOCTOR: "Enter reg. no. of Doctor",
		ENTER_DOCTOR_NAME: "Enter Doctor Name.",
		ENTER_REGISTERED_DOCTOR_NUMBER: "Enter Registered Doctor Number.",
		ENTER_NATURE_OF_HANDICAPPED: "Enter nature of Handicapped.",
		ENTER_YOUR_NAME: "Enter your Name.",
		SELECT_YOUR_GENDER: "Select your Gender",
		APPLY_FOR_NEW_ID_CARD: "Apply for New ID Card",
		ALL_FIELDS_ARE_REQUIRED: "All fields are required.",
		PLEASE_UPLOAD_ALL_NECESSARY_DOCUMENTS: "Please upload all necessary documents.",
		UPLOAD_DOCUMENTS: "Documents",
		ENTER_VALID_MOBILE_NUMBER: "Enter valid Mobile Number.",
		DIVYANG_APP: "Divyang App",
		SELECT_TERMS_AND_CONDICTION: "Select Terms & Condictions",
		PLEASE_ENTER_OTP: "OTP is required or use only numbers for OTP",
		PLEASE_ENTER_CORRECT_OTP: "Please enter correct OTP",
		PLEASE_SELECT_THE_HOSPITAL: "Please select the Hospital",
		PLEASE_SELECT_THE_DIVISION: "Please select the Division",

		EDIT: "Edit",
		BACK: "Back",
		// DOCUMENTS:"Documents" ,

		UPLOAD_RAILWAY_CONCESSION_CERTIFICATE: "Upload Railway Concession Certificate ",
		UPLOAD_DISABILITY_CERTIFICATE: "Upload Disability Certificate",
		UPLOAD_BIRTH_PROOF: "Upload Birth Proof",
		UPLOAD_ID_CARD: "Upload ID Card",
		// UPLOAD_ID_CARD:"Upload Id Card",
		UPLOAD_ADDRESS_PROOF: "Upload Address Proof",
		// UPLOAD_PHOTO:"Upload Photo",
		PLEASE_SELECT_THE_MOBILE_NO: "Please select the Mobile Number ",
		ESCORT: "Escort",
		EXTENTION_NOT_ALLOWED: "File extension not allowed. Upload only image or pdf type file.",
		MAX_FILE_ALLOWED: "Maximum 3 files are allowed.",
		MAX_FILE_SIZE_ALLOWED: "file size must be less than less than 1MB.",
		UPLOAD_ANY_ONE: "Upload any one of below:",
		ONE: "1.",
		TWO: "2.",
		THREE: "3.",
		PLEASE_ENTER_NAME: "please enter your Name.",
		PLEASE_SELECT_DATE_OF_BIRTH: "please select your date of birth.",
		PLEASE_SELECT_GENDER: "Please select your Gender.",
		PLEASE_ENTER_MOBILE_NO: "Please enter your Mobile Number.",
		PLEASE_ENTER_ADHARCARD_NUMBER: "Please enter your Aadhar card number. ",
		PLEASE_SELECT_HOSPITAL_NAME: "Please select Hospital Name.",
		PLEASE_ENTER_DOCTORNAME: "Please enter Doctor name.",
		PLEASE_ENTER_REGISTERED_DOCTOR_NUMBER: "Please enter registered Doctor Number.",
		PLEASE_ENTER_HANDICAPPED_NATURE: "Please enter Handicapped Nature.",
		PLEASE_UPLOAD_RAILWAY_CONCESSION_CERTIFICATE: "Please upload Railway Concession Certificate. ",
		PLEASE_UPLOAD_DISABILITY_CERTIFICATE: "Please upload Disability Certificate.",
		PLEASE_UPLOAD_BIRTH_PROOF: "Please upload Birth Proof.",
		PLEASE_UPLOAD_ID_CARD: "Please upload ID Card.",
		PLEASE_UPLOAD_ADDRESS_PROOF: "Please upload Address Proof.",
		PLEASE_UPLOAD_PHOTO: "Please upload Photo.",

		HEADER_HOME: "HOME",
		HEADER_ABOUT: "ABOUT",
		HEADER_FEATURES: "Features",
		HEADER_DOWNLOAD: "Download",
		HEADER_CONTACT_US: "Contact Us",
		HEADER_DOWNLOAD_DOCUMENT: "Download Document",
		HEADER_TERMS: "Terms And Conditions",

		HEADER_Privacy_Policy:"Privacy Policy",  
		

		ENTER_VALID_DOCTOR_NAME: "Enter valid Doctor Name.",
		ENTER_VALID_HANDICAPPED_NATURE: "Enter valid Handicapped Nature.",
		ENTER_VALID_NAME: "Enter valid Name",
		EXTENSION_NOTE: "Note : Files of extension '.jpg, .png, .pdf ' can only be uploaded.",

		DIVISION_NAME: "Division Name"

	}
};
