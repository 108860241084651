import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyIdCardService {

  applicantStatus: any
  currentUser: any
  getToken: any
  isUserLoggedInStatus: boolean = false

  constructor(private _router: Router,
    private _http: HttpClient
  ) { }

  getCardStatusDetail(mobileNo) {
    let url = environment.API_ENDPOINT_SECURE + "/applicantdetail/status?mobileNo=" + mobileNo;
    return this._http.get(url);
  }

  getApplicantCardDetail(mobileNo) {
    let url = environment.API_ENDPOINT_SECURE + "/applicantdetail?mobileNo=" + mobileNo;
    return this._http.get(url, { observe: 'response' });
  }

  getApplicantPhoto(filename) {
    let url = environment.API_ENDPOINT_SECURE + "/applicantdocument?filename=" + filename;
    return this._http.get(url, { observe: 'response' });
  }
  geteSignPdfDetails(mobileNo) {
    let url = environment.API_ENDPOINT_SECURE + "/eSignPdfDetails/download?mobileNo=" + mobileNo;
    return this._http.get(url);
  }
  // getApplicantCardDetail(url: any){
  //   return this._http.get(this.apiURL + url, { observe: 'response' })
  // }
}
