import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  apiURL: any = environment.API_ENDPOINT

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * 
   * @param url 
   */
  get(url: any) {
    return this._http.get(this.apiURL + url, { observe: 'response' })
  }
  getSecure(url: any) {
    return this._http.get(environment.API_ENDPOINT_SECURE + url)
  }
  getApplicantCardDetail(mobileNo) {
    let url = environment.API_ENDPOINT_SECURE + "/applicantdetail?mobileNo=" + mobileNo;
    return this._http.get(url, { observe: 'response' });
  }
}
