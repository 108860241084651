/**
 * This constant file is used for development environment
 * Summary: All constant values
 */
export const HeaderTitle = 'Rail Divyang Saarthi'
export const FooterCompanyName = ''
export const itemsPerPage = 3
export const regExp = {
	emailRegEx: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
	numRegEx: /^[0-9]+(\.?[0-9]+)?$/,
	nameRegEx:/^[a-zA-Z ]*$/

}
// export const myEasing: EasingLogic = (t: number, b: number, c: number, d: number): number => {
// 	// easeInOutExpo easing
// 	if (t === 0) {
// 		return b;
// 	}
// 	if (t === d) {
// 		return b + c;
// 	}
// 	if ((t /= d / 2) < 1) {
// 		return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
// 	}

// 	return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
// }