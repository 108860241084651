import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConcessionDocumentService } from 'src/app/services/common/concession-document.service';
import { SettingService } from 'src/app/services/common/setting-service.service';
import { CommonService } from 'src/app/services/common/common.service';

import saveAs from 'file-saver';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutusComponent implements OnInit {
  itemsPerSlide = 3;
  videoSource = 'assets/video/Final-Selection-WebApplicationRecording.mp4';
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  @ViewChild('modal', { static: false }) modal: ModalDirective;

  slides = [
    { image: 'assets/images/app_screenshot1.png' },
    { image: 'assets/images/app_screenshot2.jpg' },
    { image: 'assets/images/app_screenshot3.jpg' },
    { image: 'assets/images/app_screenshot4.jpg' },
    { image: 'assets/images/app_screenshot5.jpg' },
    { image: 'assets/images/app_screenshot1.png' },
    { image: 'assets/images/app_screenshot2.jpg' },
    { image: 'assets/images/app_screenshot3.jpg' },
    { image: 'assets/images/app_screenshot4.jpg' },
    { image: 'assets/images/app_screenshot5.jpg' },

  ];
  contacts:any;
  applicationFeatures = [
    { image: 'assets/images/app_screenshot1.png', icon: 'fa fa-trophy', heading: 'Apply', content: "You can apply for your travel concession ID card by simply filling your basic details" },
    { image: 'assets/images/app_screenshot1.png', icon: 'fa fa-tablet', heading: 'Renewal', content: "Renew your expired card by filling the old card details and expiry date" },
    { image: 'assets/images/app_screenshot2.jpg', icon: 'fa fa-crop', heading: 'Lost/Damage', content: "In case of theft/lost or damaged card, report here" },
    { image: 'assets/images/app_screenshot3.jpg', icon: 'fa fa-codepen', heading: 'My Card', content: "View/Save/Share your ID card from here" },
    { image: 'assets/images/app_screenshot4.jpg', icon: 'fa fa-pencil', heading: 'My Requests', content: "You can track your ID card request from here" },
    { image: 'assets/images/app_screenshot5.jpg', icon: 'fa fa-heart', heading: 'Instructions', content: "General instructions and guidelines for using the app" },

  ];
  featureLimit1 = Math.ceil(this.applicationFeatures.length / 2);
  applicationFeatures1 = this.applicationFeatures.slice(0, this.featureLimit1);
  applicationFeatures2 = this.applicationFeatures.slice(this.featureLimit1, this.featureLimit1 + this.applicationFeatures.length);
  featureImage = this.applicationFeatures1[0]['image'];
  slideConfigGallery = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'>>></div>",
    "prevArrow": "<div class='nav-btn prev-slide'><<</div>",
    "dots": true,
    "infinite": false,
    "outerEdgeLimit": true, "arrows": false,
    "responsive": [
      { breakpoint: 800, settings: { slidesToShow: 3 } },
      { breakpoint: 500, settings: { slidesToShow: 1 } }
    ]
  };
  slideConfigcontacts = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'>>></div>",
    "prevArrow": "<div class='nav-btn prev-slide'><<</div>",
    "dots": true,
    "infinite": false,
    "outerEdgeLimit": true, "arrows": false,
    "responsive": [
      { breakpoint: 800, settings: { slidesToShow: 3 } },
      { breakpoint: 500, settings: { slidesToShow: 1 } }
    ]
  };
  selectedIndex: number = 0;
  settings = {
    termsAndCondition: "",
    aboutUs: "",
    contactUs: ""
  }
  data:any;
  constructor(private _settingService: SettingService,private _commonService: CommonService, private _documentservice: ConcessionDocumentService) {  }

  ngOnInit() {
    this.getAllSettingDetails();
    this.getActiveDocument();   
    this.getActiveDivisionContact();
  }
  showModal() {
    this.modal.show();
  }
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  handler(type: number, $event: ModalDirective) {
    if (type == 1) {
      this.videoplayer.nativeElement.play();
    } else {
      this.videoplayer.nativeElement.pause();
    }
  }
  changeFeatureImage(item: any, index: number, right: boolean = false) {
    this.featureImage = item.image;
    if (right) {
      index = index + this.featureLimit1;
    }
    this.selectedIndex = index;
  }

  getAllSettingDetails() {
    this._settingService.getAllStaticSettings().subscribe((res) => {
      if (res && res["statusCode"] == 200) {
        let data = res["result"];
        this.settings.termsAndCondition = data["TERMS_AND_CONDITION"];
        this.settings.aboutUs = data["ABOUT_US"];
        this.settings.contactUs = data["CONTACT_US"];
      }
    })
  }
  getActiveDocument(){ 
    this._documentservice.getActiveDocumentList().subscribe((data=>{     
      this.data= data["result"]["documents"];
    }))
  }
  getActiveDivisionContact() {
    this._commonService.get('/allActiveDivisionContact').subscribe((res) => {
      let respo=res.body;
      if (respo && respo["statusCode"] == 200) {
        this.contacts = respo["result"];
      }
    });
  }

  dataURItoBlob(dataURI,type) {       
    const byteString = window.atob(dataURI.image);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type:type });    
    return blob;
 }
  
  documentDownload(data,fileName :string) {      
    this._documentservice.documentDownload(data,fileName).subscribe((res: any) => {
      let ext = fileName.split('.').pop();             
      const data = res.body.result;    
      if(ext == "pdf"){      
        const type = res.body.result.type ? res.body.result.type : "application/pdf";           
      }
      else if(ext == "png"){           
       const type = res.body.result.type ? res.body.result.type : "image/png";     
      }
      else if(ext == "jpeg"){       
        const type = res.body.result.type ? res.body.result.type : "image/jpeg";        
      }
      else if(ext == "doc"){
        const type = res.body.result.type ? res.body.result.type : "application/msword";       
      }  
      else if(ext == "docx"){
        const type = res.body.result.type ? res.body.result.type : "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ; 
      }  
      let blob = this.dataURItoBlob(data,res.type);
      saveAs(blob,fileName)        
 
    }, (err) => {
      console.log(err)
    })
	} 

}
