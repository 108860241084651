export const locale = {
	lang: 'hi',
	data: {
		NAME: "नाम",
		CARD_VALID_UPTO: "कार्ड मान्य तक",
		NATURE_OF_HANDICAPPED: "विकलांगों की प्रकृति",
		ENTER_MOBILE_NUMBER: "मोबाइल नंबर दर्ज करें",
		DIDNOT_RECEIVE_THE_OTP: "OTP प्राप्त नहीं हुआ?",
		SELECT_HOSPITAL_NAME: "अस्पताल का नाम चुनें",
		REGISTER_MOBILE: "मोबाइल रजिस्टर करें",
		AHMEDABAD_ADI: "अहमदाबाद (ADI)",
		NAME_OF_HOSPITAL: "अस्पताल का नाम",
		SUBMIT: "प्रस्तुत",
		HOSPITAL_NAME: "अस्पताल का नाम",
		DOCUMENTS: "दस्तावेज़",
		CHANGE_THE_LAUNGAGE: "भाषा बदलें",
		AADHAR_CARD_NUMBER: "आधार कार्ड नंबर",
		TERMS_AND_CONDITION_APPLIED: "नियम और शर्त लागू।",
		DONE: "किया हुआ", 
		CANCEL: "रद्द करना",
		GENDER: "लिंग",
		SELECT_GENDER: "लिंग चुनें",
		ISSUE_DATE: "जारी करने की तिथि",
		USER_NEED_TO_PAY_SOME_AMOUNT_FOR_DUPLICATE_CARD: "डुप्लिकेट कार्ड के लिए उपयोगकर्ता को कुछ राशि का भुगतान करना होगा।",
		STEP2: "चरण 2",
		UPLOAD_NEW_DOCUMENT: "नया दस्तावेज़ अपलोड करें",
		REGTD_NO_OF_DR: "डॉक्टर का पंजीकृत नंबर",
		NAME_OF_GOVDR: "सरकारी डॉक्टर का नाम",
		SELECT_HOSPITAL: "-अस्पताल का चयन करें-",
		SELECT_DIVISION: "-विभाग का चयन करें-",
		REGTD_NO_OF_DOCTOR: "डॉक्टर का पंजीकृत नंबर",
		DATE_OF_BIRTH: "जन्म की तारीख",
		SKIP: "छोड़े",
		DIVYANG_APPLICATION: "दिव्यांग आवेदन",
		ENTER_OTP_HERE: "यहाँ OTP डालें !!",
		AADHAR_CARD: "आधार कार्ड",
		APPLY_FOR_LOST_DAMAGE_CARD: "खोए और नुकसान कार्ड के लिए आवेदन करें",
		APPLICATION_STATUS: "आवेदन की स्थिति",

		NEW_APPLICATION_STATUS: "पहचान पत्र आवेदन की स्थिति",
		RENEWAL_APPLICATION_STATUS: "नवीनीकरण पहचान पत्र आवेदन की स्थिति",
		LOST_APPLICATION_STATUS: "खोया / नुकसान पहचान पत्र आवेदन की स्थिति",

		WESTERN_RAILWAY: "पश्चिम रेलवे",
		MY_PROFILE: "मेरी प्रोफाइल",
		SIGNATURE_OF_THE_ISSUING_AUTHORITY_WITH_DESIGNATION_AND_STAMP: "पदनाम और टिकट के साथ जारी करने वाले प्राधिकरण का हस्ताक्षर।",
		HOW_TO_APPLY_FOR_CARD: "कार्ड के लिए आवेदन कैसे करें",
		PERSONAL_DETAILS: "व्यक्तिगत विवरण",
		SEND_OTP: "OTP भेजें",
		STEP_ONE: "चरण 1",
		DIVISION: "विभाग:",
		IF_YOU_HAVE_LOST_YOUR_ID_CARD: "अगर आपने अपना पहचान पत्र खो दिया है। डुप्लिकेट कार्ड के लिए उपयोगकर्ता को कुछ राशि का भुगतान करना होगा।",
		ID_CARD_NO: "पहचान पत्र क्रमांक",
		RESEND_OTP: "OTP पुनः भेजें",
		CONTACT_NUMBER: "संपर्क संख्या",
		VALIDITY_OF_CONCESSION_CERTIFICATE: "रियायत प्रमाण पत्र की वैधता",
		ZONE: "क्षेत्र:",
		UPLOAD_ANY_ONE_OF_BELOW: "नीचे दिए गए किसी एक को अपलोड करें:",
		ID_CARD_FOR_DISABLE_PERSONS: "विकलांग व्यक्तियों के लिए पश्चिम रेलवे पहचान पत्र",
		NOTIFICATION_LIST: "अधिसूचना सूची",
		NOTIFICATION: "अधिसूचना",
		ABOUT_US: "हमारे बारे में",
		LOGOUT: "लॉग आउट",
		MOBILE_NO: "मोबाइल नंबर:",
		MY_REQUEST: "ट्रैक आवेदन की स्थिति",
		MY_ID_CARD: "मेरा पहचान पत्र",
		APPLY_FOR_ID_CARD: "पहचान पत्र के लिए आवेदन करें",
		RENEWAL_ID_CARD: "नवीनीकरण पहचान पत्र",
		LOST_DAMAGE_ID: "खोया / नुकसान पहचान पत्र",
		INSTRUCTION: "अनुदेश",
		CONSESSION_CERTIFICATE: "विशेष सुविधा प्रमाण पत्र",
		DISABILITY_CERTIFICATE: "विकलांगता प्रमाण पत्र",
		BIRTH_PROOF: "जन्म प्रमाण",
		ID_CARD: "पहचान पत्र",
		ADDRESS_PROOF: "पते का सबूत",
		PHOTO: "फोटो",
		APPROVED: "स्वीकृत",
		DOCUMENT_VERIFICATION: "दस्तावेज़ सत्यापन",
		PROCESSING: "प्रसंस्करण",
		REQUEST_SUBMITTED: "अनुरोध सबमिट किया गया",
		RAILWAY_CONCESSION_CERTIFICATION: " रेलवे विशेष सुविधा प्रमाण पत्र",
		UPLOAD_PHOTO: "फोटो अपलोड करें",
		LEAVING_CERTIFICATE: "छोड़ने के प्रमाण पत्र",
		BIRTH_CERTIFICATE: "जन्म प्रमाणपत्र",
		ELECTION_CARD: "चुनाव कार्ड",
		PAN_CARD: "पैन कार्ड",
		DRIVING_LICENSE: "ड्राइविंग लाइसेंस",
		MALE: "पुरुष",
		FEMALE: "स्त्री",
		PREFER_NOT_TO_DISCLOSE: "खुलासा नहीं करना पसंद करते हैं",
		DO_YOU_WANT_TO_LOGOUT: "क्या आप लॉगआउट करना चाहते हैं?",
		YES: "हाँ",
		NO: "नहीं",
		TERMS_AND_CONDITIONS: "नियम और शर्तें ",
		TERMS_AND_CONDITIONS_APPLIED: "लागू।",
		SELECT_LANGUAGE: "भाषा का चयन करें",
		NAME_OF_DOCTOR: "डॉक्टर का नाम",
		CLOSE: "बंद करे",
		APPLICANT_NAME: "आवेदक का नाम",
		ID_CARD_RENEWAL: "पहचान पत्र नवीनीकरण",
		EDIT_ID_CARD: "पहचान पत्र संपादित करे",
		FROM_GALLERY: "गैलरी से",
		FROM_DOCUMENT: "दस्तावेज़ से",
		SELECT_OPTION: "विकल्प चुनें",
		TAKE_PHOTO: "फोटो लो",
		CHOOSE_FROM_GALLERY: "गैलरी से चयन करो",
		SETTINGS: "समायोजन",
		ENTER_NAME: "नाम दर्ज करे",
		SELECT_DATE_OF_BIRTH: "जन्म तिथि चुनें",
		ENTER_ADHARCARD_NUMBER: "आधार कार्ड नंबर दर्ज करें",
		ENTER_VALID_ADHARCARD_NUMBER: "मान्य आधार कार्ड नंबर दर्ज करें",
		SELECT_HOSPITALNAME: "अस्पताल का नाम चुनें",
		ENTER_DOCTORNAME: "डॉक्टर का नाम दर्ज करें",
		ENTER_HANDICAPPED_NATURE: "विकलांग प्रकृति दर्ज करें",
		ENTER_REG_NO_OF_DOCTOR: "डॉक्टर का पंजीकृत नंबर दर्ज करें",
		ENTER_DOCTOR_NAME: "",
		ENTER_REGISTERED_DOCTOR_NUMBER: "डॉक्टर का पंजीकृत नंबर दर्ज करें",
		ENTER_NATURE_OF_HANDICAPPED: "विकलांग प्रकृति दर्ज करें",
		ENTER_YOUR_NAME: "अपना नाम दर्ज करें।",
		SELECT_YOUR_GENDER: "अपना लिंग चुनें",
		APPLY_FOR_NEW_ID_CARD: "नए पहचान पत्र के लिए आवेदन करें",
		ALL_FIELDS_ARE_REQUIRED: "सभी फ़ील्ड आवश्यक हैं।",
		PLEASE_UPLOAD_ALL_NECESSARY_DOCUMENTS: "कृपया सभी आवश्यक दस्तावेज अपलोड करें।",
		UPLOAD_DOCUMENTS: "दस्तावेज़ अपलोड करें",
		ENTER_VALID_MOBILE_NUMBER: "मान्य मोबाइल नंबर दर्ज करें",
		DIVYANG_APP: "दिव्यांग ऐप",
		SELECT_TERMS_AND_CONDICTION: "नियम और शर्तें चुनें",
		PLEASE_ENTER_OTP: "OTP आवश्यक है या OTP के लिए केवल संख्याओं का उपयोग करें",
		PLEASE_ENTER_CORRECT_OTP: "कृपया सही OTP दर्ज करें",
		PLEASE_SELECT_THE_HOSPITAL: "कृपया अस्पताल का चयन करें",
		PLEASE_SELECT_THE_DIVISION: "कृपया विभाग का चयन करें",
		EDIT: "संपादित करें",
		BACK: "वापस",
		UPLOAD_RAILWAY_CONCESSION_CERTIFICATE: "रेलवे विशेष सुविधा प्रमाण पत्र अपलोड करें",
		UPLOAD_DISABILITY_CERTIFICATE: "विकलांगता प्रमाण पत्र अपलोड करें",
		UPLOAD_BIRTH_PROOF: "जन्म प्रमाण पत्र अपलोड करें",
		UPLOAD_ID_CARD: "पहचान पत्र अपलोड करें",
		UPLOAD_ADDRESS_PROOF: "पते का सबूत अपलोड करें",
		PLEASE_SELECT_THE_MOBILE_NO: "कृपया मोबाइल नंबर चुनें",
		ESCORT: "अनुरक्षण",
		EXTENTION_NOT_ALLOWED: "फ़ाइल एक्सटेंशन की अनुमति नहीं है| केवल छवि या पीडीएफ टाइप फ़ाइल अपलोड करें|",
		MAX_FILE_ALLOWED: "अधिकतम 3 फ़ाइलों की अनुमति है.",
		MAX_FILE_SIZE_ALLOWED: "फ़ाइल का आकार 1 एमबी से कम होना चाहिए।",
		UPLOAD_ANY_ONE: "नीचे दिए गए किसी एक को अपलोड करें",
		ONE: "१.",
		TWO: "२.",
		THREE: "३.",

		PLEASE_ENTER_NAME: "कृपया अपना नाम दर्ज करें.",
		PLEASE_SELECT_DATE_OF_BIRTH: "कृपया अपना जन्म तिथि चुनें",
		PLEASE_SELECT_GENDER: "कृपया अपना लिंग चुनें",
		PLEASE_ENTER_MOBILE_NO: "कृपया अपना मोबाइल नंबर दर्ज करें.",
		PLEASE_ENTER_ADHARCARD_NUMBER: "कृपया मान्य आधार कार्ड नंबर दर्ज करें.",
		PLEASE_SELECT_HOSPITAL_NAME: "कृपया अस्पताल का नाम चुनें",
		PLEASE_ENTER_DOCTORNAME: "कृपया डॉक्टर का नाम दर्ज करें.",
		PLEASE_ENTER_REGISTERED_DOCTOR_NUMBER: "कृपया डॉक्टर का पंजीकृत नंबर दर्ज करें.",
		PLEASE_ENTER_HANDICAPPED_NATURE: "कृपया विकलांग प्रकृति दर्ज करें.",
		PLEASE_UPLOAD_RAILWAY_CONCESSION_CERTIFICATE: "कृपया रेलवे विशेष सुविधा प्रमाण पत्र अपलोड करें",
		PLEASE_UPLOAD_DISABILITY_CERTIFICATE: "कृपया विकलांगता प्रमाण पत्र अपलोड करें",
		PLEASE_UPLOAD_BIRTH_PROOF: "कृपया जन्म प्रमाण पत्र अपलोड करें",
		PLEASE_UPLOAD_ID_CARD: "कृपया पहचान पत्र अपलोड करें",
		PLEASE_UPLOAD_ADDRESS_PROOF: "कृपया पते का सबूत अपलोड करें",
		PLEASE_UPLOAD_PHOTO: "कृपया फोटो अपलोड करें",

		HEADER_HOME: "घर",
		HEADER_ABOUT: "के बारे में",
		HEADER_FEATURES: "विशेषताएं",
		HEADER_DOWNLOAD: "डाउनलोड",
		HEADER_CONTACT_US: "संपर्क करें",
		HEADER_DOWNLOAD_DOCUMENT: "दस्तावेज़ डाउनलोड",
		HEADER_TERMS: "निबंधन और शर्तें",
		ENTER_VALID_DOCTOR_NAME: "मान्य डॉक्टर का नाम दर्ज करें।",
		ENTER_VALID_HANDICAPPED_NATURE: "मान्य विकलांग प्रकृति दर्ज करें।",
		ENTER_VALID_NAME: "मान्य नाम दर्ज करें।",
		EXTENSION_NOTE: "नोट: एक्सटेंशन की फाइलें '.jpg, .png, .pdf ' केवल अपलोड की जा सकती हैं।",

		DIVISION_NAME: "विभाग नाम"

	}
};
