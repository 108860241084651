import { Component, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

// language list
import { locale as enLang } from './localization/i18n/en';
import { locale as gjLang } from './localization/i18n/gj';
import { locale as hiLang } from './localization/i18n/hi';
import { TranslationService } from './services/common/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Divyang';
  constructor(
    private translationService: TranslationService,
    private _titleService: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.translationService.loadTranslations(enLang, gjLang, hiLang);
  }

  ngOnInit() {
    /**
     * following code is use for set current page title
     */
    const appTitle = this._titleService.getTitle()
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this._activatedRoute.firstChild;
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title']
        }
        return appTitle
      })
    ).subscribe((ttl: string) => {
      this._titleService.setTitle(ttl)
    });
  }
}
