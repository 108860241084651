import { Component, OnInit, HostBinding } from '@angular/core';
import { AuthService } from '../../../auth/service/auth.service';
import { Router, NavigationStart } from '@angular/router';
import { TranslationService } from 'src/app/services/common/translation.service';
import { filter } from 'rxjs/operators';

interface LanguageFlag {
	lang: string;
	name: string;
	active?: boolean;
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@HostBinding('class') classes = '';
	sticky: boolean = false;
	elementPosition: any;

	language;
	languages: LanguageFlag[] = [
		{
			lang: 'en',
			name: 'English',
		},
		{
			lang: 'hi',
			name: 'Hindi'
		},
		{
			lang: 'gj',
			name: 'Gujarati'
		}
	];

	constructor(private translationService: TranslationService, public _authService: AuthService, private _router: Router) { }

	ngOnInit() {
		this.setSelectedLanguage();
		this._router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});
	}

	doLogOut() {
		localStorage.clear();
		window.location.reload();
	}

	/**
	   * Set language
	   *
	   * @param lang: any
	   */
	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language.lang;
			} else {
				language.active = false;
			}
		});
		this.translationService.setLanguage(lang);
	}

	/**
	 * Set selected language
	 */
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}



}
