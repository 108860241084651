import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router: Router) { }
  currentUrl: any = '';
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let currentStatus = localStorage.getItem('currentStatus') ? localStorage.getItem('currentStatus') : ''
    let cardType = localStorage.getItem('cardType');
    let token = localStorage.getItem('token');
    if (token && token != '') {
      //   // if (currentStatus && currentStatus != '') {
      //   //   if (new Number(currentStatus) == 1 && state.url != '/ ' && state.url !== "/my-request") {
      //   //     this._router.navigate(['/'])
      //   //   } else if (new Number(currentStatus) == 2 && state.url != '/ ' && state.url === "/admitcard") {
      //   //     this._router.navigate(['/'])
      //   //   } else if (new Number(currentStatus) == 3 && state.url != '/ ' && (state.url === "/my-request" || state.url === "/my-card")) {
      //   //     this._router.navigate(['/'])
      //   //   }
      //   // }
      if (currentStatus == 'null' && (state.url === '/my-request' || state.url === '/my-card')) {
        this._router.navigate(['/']);
        return false;
      }
      if (currentStatus && currentStatus === 'APPROVED' && state.url == '/admitcard') {
        this._router.navigate(['/']);
        return false;
      }
      if (currentStatus != 'null' && currentStatus != 'APPROVED' && cardType && cardType == 'New Card' && (state.url == '/lost-and-damage-card' || state.url == '/renewalcard' || state.url == '/my-card')) {
        this._router.navigate(['/']);
        return false;
      }
      if (currentStatus != 'null' && currentStatus != 'APPROVED' && cardType && cardType == 'Renewal Card' && (state.url == '/lost-and-damage-card' || state.url == '/admitcard' || state.url == '/my-card')) {
        this._router.navigate(['/']);
        return false;
      }
      if (currentStatus != 'null' && currentStatus != 'APPROVED' && cardType && cardType != 'Renewal Card' && cardType != 'New Card' && (state.url == '/admitcard' || state.url == '/renewalcard' || state.url == '/lost-and-damage-card' || state.url == '/my-card')) {
        this._router.navigate(['/']);
        return false;
      }

      // if (cardType == 'New Card' && (state.url !== '/admitcard' || state.url !== '/my-request')) {

      // }
      // if (currentStatus && currentStatus == null && state.url != '/admitcard' && state.url !== "/lost-and-damage-card") {
      //   this._router.navigate(['/dashboard']);
      //   return true;

      // } else if (currentStatus && currentStatus == 'APPROVED' && state.url == '/admitcard') {
      //   this._router.navigate(['/dashboard']);
      //   return true;
      // } else {
      //   if (cardType && cardType == 'New Card' && state.url != '/admitcard' && state.url != '/my-request') {
      //     this._router.navigate(['/dashboard']);
      //     return true;
      //   } else if (cardType && cardType == 'Renewal Card' && state.url != '/renewalcard' && state.url != '/my-request') {
      //     // this._router.navigate(['/'])
      //     // return false;
      //     this._router.navigate(['/dashboard']);
      //     return true;
      //     console.log("MATCH 4");
      //   }
      // }
    }

    !token ? this._router.navigate(['/about']) : ''
    return (token && token != '') ? true : false
  }
}
