export class MyIdCard {
    mobileNo: number;
    name: string;
    dateOfBirth: string;
    cardType: any;
    gender: string;
    aadharCard: number;
    divisionId: any;
    divisionName: string;
    hospitalId: any;
    hospitalName: string;
    doctorName: string;
    doctorRegdNo: any;
    handicappedNature: any;
    concessionCertiValidity: any;
    generatedCardNo: any;
    issueDttm: string;
    dueDttm: string;
    railwayConcessionCertificate: any[];
    disabilityCertificate: any[];
    birthProof: Date[];
    idCard: any[];
    addressProof: string[];
    photo: any[];
    escort: string;
}