import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private _httpClient: HttpClient) { }

  getAllSettings() {
    let url = environment.API_ENDPOINT + "/settings";
    return this._httpClient.get(url);
  }

  getAllStaticSettings() {
    let url = environment.API_ENDPOINT + "/settingsdiv";
    return this._httpClient.get(url);
  }
  
}
