// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,


  //local project and api testing 
  //API_ENDPOINT: 'http://localhost:8081/v1/json',
  //API_ENDPOINT_V2: 'http://localhost:8081/v2/json',
  //API_ENDPOINT_SECURE: 'http://localhost:8081/api/v1/json'

  //live env
  API_ENDPOINT_SECURE: 'https://admin.railsaarthi.in/api/v1/json',
  API_ENDPOINT: 'https://admin.railsaarthi.in/v1/json',
  API_ENDPOINT_V2: 'https://admin.railsaarthi.in/v2/json',

  // KCS Testing
  //  API_ENDPOINT_SECURE: 'http://54.37.162.102:63539/api/v1/json',  
  //   API_ENDPOINT: 'http://54.37.162.102:63539/v1/json',
  //   API_ENDPOINT_V2: 'http://54.37.162.102:63539/v2/json',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
