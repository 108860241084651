import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiURL: any = environment.API_ENDPOINT
  applicantStatus: any
  currentUser: any
  getToken: any
  isUserLoggedInStatus: boolean = false

  constructor(
    private _router: Router,
    private _http: HttpClient
  ) { }

  /**
   * 
   */
  isUserLoggedIn() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.applicantStatus = localStorage.getItem('applicantStatus')
    this.getToken = localStorage.getItem('token')

    this.isUserLoggedInStatus = (this.getToken && this.currentUser) ? true : false
  }
  /**
   * 
   * @param url
   * @param userDetail 
   */
  post(url: any, userDetail) {
    return this._http.post(this.apiURL + url, userDetail, { observe: 'response' })
  }

  /**
   * 
   * @param url 
   */
  get(url: any) {
    return this._http.get(this.apiURL + url, { observe: 'response' })
  }

  /**
   * 
   */
  logout() {
    localStorage.clear()
    this.isUserLoggedInStatus = false
    this._router.navigate(['/auth/login'])
  }


  /**
   * 
   */
  getActiveHospitals() {
    let url = environment.API_ENDPOINT + "/hospitals";
    return this._http.get(url);
  }
  getAllDevisions() {
    let url = environment.API_ENDPOINT + "/divisions";
    return this._http.get(url);
  }
  hospitalByDivisionId(divisionId) {
    let url = environment.API_ENDPOINT + "/hospitalbydivisionid?divisionId=" + divisionId;
    return this._http.get(url);
  }

  /**
   * @param mobileNumber
   * @info to send otp to user
   */
  sendOTP(mobileNo, hospital, division) {
    let url = environment.API_ENDPOINT_V2 + "/sendotp?mobileNo=" + mobileNo + "&hospitalId=" + hospital + "&divisionId=" + division;
    return this._http.post(url, {})
  }

  /**
   * 
   */
  verifyOtp(divisionId, hospitalId, mobileNo, otp) {
    //let url = environment.API_ENDPOINT + "/verifyotp?mobileNo=" + mobileNo + "&otp=" + otp;
    let url = environment.API_ENDPOINT_V2 + "/verifyotp?mobileNo=" + mobileNo + "&otp=" + otp + "&hospitalId=" + hospitalId + "&divisionId=" + divisionId;
    return this._http.post(url, {})
  }

  /**
   * 
   */
  getUserByToken() {
    let url = environment.API_ENDPOINT + "/getUserByToken";
    return this._http.get(url);
  }
}