import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../shared/components/confirm/confirm.component';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  constructor(private SimpleModalService: SimpleModalService) { }
  dropDownChoices: string[] = [
    'Election Card',
    'Aadhar Card',
    'Pan Card'
  ];

  ngOnInit() {
  }
  showConfirm() {
    this.SimpleModalService.addModal(ConfirmComponent, {
      title: 'Confirmation',
      message: 'confirm some action?'
    })
      .subscribe((isConfirmed) => {
        // Get modal result
        if (isConfirmed) {
          alert('confirmed')
        }
      });
  }

}
