import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantCardStatusService } from 'src/app/services/applicant-card-status/appplicant-card-status-service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { ApplicantCardStatus } from 'src/app/models/applicant-card-status';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-my-request',
  templateUrl: './my-request.component.html',
  styleUrls: ['./my-request.component.scss']
})
export class MyRequestComponent implements OnInit {
  mobileNo: string;
  appStatusDetail: ApplicantCardStatus;
  currentStatusCtr: number;
  newCardStatus: boolean;
  renewCardStatus: boolean;
  lostCardStatus: boolean;

  constructor(
    private _router: Router,
    private _applicantCardStatusService: ApplicantCardStatusService,
    private _toastrService: ToastrService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.appStatusDetail = new ApplicantCardStatus();
    this.currentStatusCtr = 1;
    this.newCardStatus = true;
    this.renewCardStatus = false;
    this.lostCardStatus = false;
    this.getApplicantCardStatusDetail();
  }

  getApplicantCardStatusDetail() {
    this.mobileNo = localStorage.getItem('mobileNo');

    this._applicantCardStatusService.getApplicantCardStatusDetail(this.mobileNo).subscribe(res => {
        if (res.status == 200 && res.body && res.body["result"] && res.body["result"]["status"]) {
            this.appStatusDetail = res.body["result"]["status"]
            if (this.appStatusDetail.currentStatus == 'REQUEST') {
              this.currentStatusCtr = 1;
            } else if (this.appStatusDetail.currentStatus == 'PROCESSED') {
              this.currentStatusCtr = 2;
            } else if (this.appStatusDetail.currentStatus == 'VERIFIED') {
              this.currentStatusCtr = 3;
            } else if (this.appStatusDetail.currentStatus == 'APPROVED') {
              this.currentStatusCtr = 4;
            }

            if (this.appStatusDetail.cardType == 'New Card') {
                this.newCardStatus = true;
                this.renewCardStatus = false;
                this.lostCardStatus = false;
            } else if (this.appStatusDetail.cardType == 'Renewal Card') {
                this.newCardStatus = false;
                this.renewCardStatus = true;
                this.lostCardStatus = false;
            } else if (this.appStatusDetail.cardType == 'Damaged Card') {
                this.newCardStatus = false;
                this.renewCardStatus = false;
                this.lostCardStatus = true;
            }
        }
    })

  }
}
