import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApplicantCardStatusService {

    constructor(private _http: HttpClient
    ) { }

    getApplicantCardStatusDetail(mobileNo) {
        let url = environment.API_ENDPOINT_SECURE + "/applicantdetail/status?mobileNo=" + mobileNo;
        return this._http.get(url, { observe: 'response' });
    }

    getApplicantStatus(mobileNo) {
        let url = environment.API_ENDPOINT_SECURE + "/applicant/curruntstatus?mobileNo=" + mobileNo;
        return this._http.get(url, { observe: 'response' });
    }
}