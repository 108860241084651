import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './../../services/common/common.service';
import { regExp } from './../../shared/constant/global-constants';
import { PostService } from './../../services/post/post.service';
import * as moment from 'moment';
import Speech from "speak-tts";
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-admit-card',
  templateUrl: './admit-card.component.html',
  styleUrls: ['./admit-card.component.scss']
})
export class AdmitCardComponent implements OnInit {

  modalRef: BsModalRef;

  BirthProofChoices: string[] = [
    'Birth Certificate',
    'Leaving Certificate'
  ];
  IdcardChoices: string[] = [
    'Election Card',
    'Aadhar Card',
    'Pan Card'
  ];
  AddressChoices: string[] = [
    'Election Card',
    'Aadhar Card',
    'Driving Licence'
  ];
  hospital: Array<any> = []
  divisions = [];
  admitcardForm: FormGroup;
  submitted: boolean = false
  maxDate: Date;

  railwayConcessionCertificate: any = [];
  disabilityCertificate: any = [];
  birthProof: any = [];
  idCard: any = [];
  addressProof: any = [];
  photo: any = [];

  railwayConcessionCertificateIMG: string = "assets/images/concession_certification.png";
  disabilityCertificateIMG: string = "assets/images/disability_certificate.png";
  birthProofIMG: string = "assets/images/birth_proof.png";
  idCardIMG: string = "assets/images/id-card.png";
  addressProofIMG: string = "assets/images/address_proof.png";
  photoIMG: string = "assets/images/photo.png";

  railwayConcessionCertificateLength: number = 0;
  disabilityCertificateLength: number = 0;
  birthProofLength: number = 0;
  idCardLength: number = 0;
  addressProofLength: number = 0;
  photoLength: number = 0;

  idCardselect: string = '';
  addressProofselect: string = '';
  fileInputCheck: any = {
    railwayConcessionCertificate: false,
    disabilityCertificate: false,
    photo: false,
    birthProof: false,
    idCard: false,
    addressProof: false
  }
  getMobileNo: any;
  getHospitalId: number = 0;
  getDivisionId: number = 0;
  IDCardData: any;
  editIDCard: boolean = false;
  documentName: string;
  cardType: number = 1;
  constructor(private _commonService: CommonService,
    private _postService: PostService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastrService: ToastrService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private imageCompress: NgxImageCompressService,
  ) {
    this.createForm();
  }


  openModal(template: TemplateRef<any>, controlName: string) {
    this.documentName = '';
    this.documentName = controlName;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    if (this.documentName == 'railwayConcessionCertificate') {
      this.fileInputCheck[this.documentName] = true;
      this.railwayConcessionCertificateLength = 0;
      this.railwayConcessionCertificate = [];
      this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
    }
    if (this.documentName == 'disabilityCertificate') {
      this.fileInputCheck[this.documentName] = true;
      this.disabilityCertificateLength = 0;
      this.disabilityCertificate = [];
      this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
    }
    if (this.documentName == 'birthProof') {
      this.birthProofLength = 0;
      this.birthProof = [];
      this.birthProofIMG = "assets/images/birth_proof.png";
    }
    if (this.documentName == 'idCard') {
      this.idCardLength = 0;
      this.idCard = [];
      this.idCardIMG = "assets/images/id-card.png";
    }
    if (this.documentName == 'addressProof') {
      this.addressProofLength = 0;
      this.addressProof = [];
      this.addressProofIMG = "assets/images/address_proof.png";
    }
    if (this.documentName == 'photo') {
      this.fileInputCheck[this.documentName] = true;
      this.photoLength = 0;
      this.photo = [];
      this.photoIMG = "assets/images/photo.png";
    }

    //this.admitcardForm.controls[this.documentName].setErrors({ required: true });
    this.admitcardForm.controls[this.documentName].setValidators([Validators.required]);
    this.admitcardForm.controls[this.documentName].updateValueAndValidity();
    this.modalRef.hide();

  }

  decline(): void {
    this.modalRef.hide();
  }


  ngOnInit() {

    this.getAllDevisions();
    //this.getHospital();
    this.getMobileNo = localStorage.getItem('mobileNo');
    this.getHospitalId = Number(localStorage.getItem('hospitalId'));
    this.getDivisionId = Number(localStorage.getItem('divisionId'));
    this.hospitalByDivisionId(this.getDivisionId);
    this.maxDate = new Date();
    // let cardType = localStorage.getItem('cardType');
    // this.cardType = cardType ? new Number(cardType) : 1;
    // localStorage.removeItem('cardType');
    this._commonService.getApplicantCardDetail(this.getMobileNo).subscribe(data => {
      if (data.status && data.body && data.body['result'] && data.body['result']['applicantDetail']) {
        let res = data.body['result']['applicantDetail'];
        this.IDCardData = res;
        if (res.mobileNo != null) {

          this.editIDCard = true;
          if (this.IDCardData.dateOfBirth) {
            this.IDCardData.dateOfBirth = moment(this.IDCardData.dateOfBirth).format('DD/MM/YYYY')
          } else {
            this.IDCardData.dateOfBirth = '';
          }
          this.IDCardData.cardType = this.cardType;

          this.admitcardForm.controls['railwayConcessionCertificate'].clearValidators();
          this.admitcardForm.controls['railwayConcessionCertificate'].updateValueAndValidity();
          this.admitcardForm.controls['disabilityCertificate'].clearValidators();
          this.admitcardForm.controls['disabilityCertificate'].updateValueAndValidity();
          this.admitcardForm.controls['birthProof'].clearValidators();
          this.admitcardForm.controls['birthProof'].updateValueAndValidity();
          this.admitcardForm.controls['idCard'].clearValidators();
          this.admitcardForm.controls['idCard'].updateValueAndValidity();
          this.admitcardForm.controls['addressProof'].clearValidators();
          this.admitcardForm.controls['addressProof'].updateValueAndValidity();
          this.admitcardForm.controls['photo'].clearValidators();
          this.admitcardForm.controls['photo'].updateValueAndValidity();

          this.railwayConcessionCertificateLength = this.IDCardData.railwayConcessionCertificate.length ? this.IDCardData.railwayConcessionCertificate.length : 0;
          this.disabilityCertificateLength = this.IDCardData.disabilityCertificate.length ? this.IDCardData.disabilityCertificate.length : 0;
          this.birthProofLength = this.IDCardData.birthProof.length ? this.IDCardData.birthProof.length : 0;
          this.idCardLength = this.IDCardData.idCard.length ? this.IDCardData.idCard.length : 0;
          this.addressProofLength = this.IDCardData.addressProof.length ? this.IDCardData.addressProof.length : 0;
          this.photoLength = this.IDCardData.photo.length ? this.IDCardData.photo.length : 0;

          this.railwayConcessionCertificateIMG = "assets/images/concession_certification_upload.png";
          this.disabilityCertificateIMG = "assets/images/concession_certification_upload.png";
          this.birthProofIMG = "assets/images/birth_proof_upload.png";
          this.idCardIMG = "assets/images/id-card_upload.png";
          this.addressProofIMG = "assets/images/address_proof_upload.png";
          this.photoIMG = "assets/images/photo_uploaded.png";
          //this.cdr.detectChanges();
        }

        this.IDCardData.hospitalId = this.getHospitalId;
        this.IDCardData.divisionId = this.getDivisionId;
        this.IDCardData.mobileNo = this.getMobileNo;

        this.patchValues();
      }
    });

  }

  createForm() {
    this.admitcardForm = this._formBuilder.group({
      name: ['', Validators.compose(
        [
          Validators.required,
          Validators.pattern(regExp.nameRegEx),
          Validators.minLength(3),
          Validators.maxLength(100),
        ])]
      ,
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      cardType: [this.cardType],
      mobileNo: [{ value: '', disabled: true }, Validators.compose([
        //Validators.required,
        Validators.pattern(regExp.numRegEx),
        Validators.minLength(10),
        Validators.maxLength(10)
      ])],
      aadharCard: ['', Validators.compose([
        Validators.required,
        Validators.pattern(regExp.numRegEx),
        Validators.minLength(12),
        Validators.maxLength(12)
      ])],
      divisionId: [''],
      hospitalId: ['', Validators.required],
      doctorName: ['', Validators.compose(
        [
          Validators.required,
          Validators.pattern(regExp.nameRegEx),
          Validators.minLength(3),
          Validators.maxLength(100),
        ])
      ],
      doctorRegdNo: ['', Validators.required],
      handicappedNature: ['', Validators.compose(
        [
          Validators.required,
          Validators.pattern(regExp.nameRegEx),
          Validators.minLength(3),
          Validators.maxLength(100),
        ])
      ],
      railwayConcessionCertificate: ['', Validators.required],
      disabilityCertificate: ['', Validators.required],
      birthProof: ['', Validators.required],
      idCard: ['', Validators.required],
      addressProof: ['', Validators.required],
      photo: ['', Validators.required],
      isEditMode: [false]

    })
  }
  patchValues() {
    this.admitcardForm.patchValue({
      name: this.IDCardData.name,
      dateOfBirth: this.IDCardData.dateOfBirth,
      gender: this.IDCardData.gender,
      cardType: this.cardType,
      mobileNo: this.IDCardData.mobileNo,
      aadharCard: this.IDCardData.aadharCard,
      hospitalId: this.IDCardData.hospitalId,
      divisionId: this.IDCardData.divisionId,
      doctorName: this.IDCardData.doctorName,
      doctorRegdNo: this.IDCardData.doctorRegdNo,
      handicappedNature: this.IDCardData.handicappedNature
    });
  }

  onSubmit() {
    this.submitted = true
    const controls = this.admitcardForm.controls;
    // stop here if form is invalid
    if (this.admitcardForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }


    const admitcardFormValue = this.prepareData();

    setTimeout(() => {
      if (this.admitcardForm.valid) {
        this._postService.post_form('/applicantdetail/submit', admitcardFormValue.form).subscribe(res => {
          if (res.status === 200) {
            localStorage.setItem("hospitalId", this.admitcardForm.controls.hospitalId.value);
            localStorage.setItem("divisionId", this.admitcardForm.controls.divisionId.value);
            this._toastrService.success(res.body['message'], 'Success')
            this._router.navigate(['/'])
          }
        }, error => {
          var fieldError = ""
          if (error['error']) {
            fieldError = error['error'];
          } else {
            fieldError = "Something went wrong, please try again or after sometime."
          }
          this._toastrService.error(fieldError, 'Error')
        })
      }
    });
  }

  prepareData(): any {
    const controls = this.admitcardForm.controls;
    const _admin_card: any = {};

    let dt = controls['dateOfBirth'].value;
    let dtSend = moment(dt).format('YYYY-MM-DD HH:mm:ss');
    _admin_card.name = controls['name'].value;
    _admin_card.dateOfBirth = dtSend;
    _admin_card.cardType = this.cardType;
    _admin_card.gender = controls['gender'].value;
    _admin_card.mobileNo = controls['mobileNo'].value;
    _admin_card.aadharCard = controls['aadharCard'].value;
    _admin_card.hospitalId = controls['hospitalId'].value;
    _admin_card.divisionId = controls['divisionId'].value;
    _admin_card.doctorName = controls['doctorName'].value;
    _admin_card.doctorRegdNo = controls['doctorRegdNo'].value;
    _admin_card.handicappedNature = controls['handicappedNature'].value;

    const formData = new FormData();
    Object.keys(_admin_card).forEach(item => {
      formData.append(item, _admin_card[item]);
    })

    if (this.editIDCard) {
      formData.append('isEditMode', 'true');
    } else {
      formData.append('isEditMode', 'false');
    }

    if (this.railwayConcessionCertificate != null && this.railwayConcessionCertificate != '') {
      for (let i = 0; i < this.railwayConcessionCertificate.length; i++) {
        formData.append("railwayConcessionCertificate", this.railwayConcessionCertificate[i]);
      }
    }
    if (this.disabilityCertificate != null && this.disabilityCertificate != '') {
      for (let i = 0; i < this.disabilityCertificate.length; i++) {
        formData.append("disabilityCertificate", this.disabilityCertificate[i]);
      }
    }
    if (this.birthProof != null && this.birthProof != '') {
      for (let i = 0; i < this.birthProof.length; i++) {
        formData.append("birthProof", this.birthProof[i]);
      }

    }
    if (this.idCard != null && this.idCard != '') {
      for (let i = 0; i < this.idCard.length; i++) {
        formData.append("idCard", this.idCard[i]);
      }

    }
    if (this.addressProof != null && this.addressProof != '') {
      for (let i = 0; i < this.addressProof.length; i++) {
        formData.append("addressProof", this.addressProof[i]);
      }

    }
    if (this.photo != null && this.photo != '') {
      for (let i = 0; i < this.photo.length; i++) {
        formData.append("photo", this.photo[i]);
      }
    }
    return { form: formData, _admin_card: _admin_card };
  }

  onPreventClick(event: MouseEvent, item: string) {
    if (!this.fileInputCheck[item]) {
      if (item == "railwayConcessionCertificate" && this.editIDCard) {
        event.preventDefault();
      }
      if (item == "disabilityCertificate" && this.editIDCard) {
        event.preventDefault();
      }
      if (item == "birthProof") {
        event.preventDefault();
      }
      if (item == "idCard") {
        event.preventDefault();
      }
      if (item == "addressProof") {
        event.preventDefault();
      }
      if (item == "photo" && this.editIDCard) {
        event.preventDefault();
      }

    }
  }

  enableValue(controlName: string, value: string) {

    this.fileInputCheck[controlName] = true;
    this.admitcardForm.controls[controlName].setValue('');
    if (controlName == 'idCard') {
      this.idCardselect = value;
      if (this.idCardselect == this.addressProofselect) {
        if (this.addressProof) {
          this.idCardIMG = "assets/images/id-card_upload.png";
          this.idCard = this.addressProof;
          this.idCardLength = this.addressProofLength;
          //this.admitcardForm.controls['idCard'].setErrors(null);
          this.admitcardForm.controls['idCard'].clearValidators();
          this.admitcardForm.controls['idCard'].updateValueAndValidity();
        }
      } else {
        this.idCardIMG = "assets/images/id-card.png";
      }
    }
    if (controlName == 'addressProof') {
      this.addressProofselect = value;
      if (this.idCardselect == this.addressProofselect) {
        if (this.idCard) {
          this.addressProofIMG = "assets/images/address_proof_upload.png";
          this.addressProof = this.idCard;
          this.addressProofLength = this.idCardLength;
          //this.admitcardForm.controls['addressProof'].setErrors(null);
          this.admitcardForm.controls['addressProof'].clearValidators();
          this.admitcardForm.controls['addressProof'].updateValueAndValidity();
        }
      } else {
        this.addressProofIMG = "assets/images/address_proof.png";
      }
    }

  }

  onFileSelected(event, controlName: string) {

    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      var filesLength = event.target.files.length;


      if (controlName == 'idCard') {
        this.idCard = [];
      }
      if (controlName == 'addressProof') {
        this.addressProof = [];
      }

      //max file upload validation
      if (filesLength > 3) {
        this.admitcardForm.controls[controlName].setErrors({ 'maxallowedFile': true });
        return;
      }

      for (let i = 0; i < filesLength; i++) {
        var fileSize = 0;
        var newImagefile;
        //file extention  validation
        const allowedFileExt = ['jpg', 'jpeg', 'png', 'HEIC', 'heic', 'pdf'];
        var ext = event.target.files[i].name.substr(event.target.files[i].name.lastIndexOf('.') + 1).toLowerCase();
        if (ext == '') return;
        if (allowedFileExt.indexOf(ext) == -1) {
          if (controlName == 'railwayConcessionCertificate') {
            this.railwayConcessionCertificateLength = 0;
            this.railwayConcessionCertificate = [];
            this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
          }
          if (controlName == 'disabilityCertificate') {
            this.disabilityCertificateLength = 0;
            this.disabilityCertificate = [];
            this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
          }
          if (controlName == 'birthProof') {
            this.birthProofLength = 0;
            this.birthProof = [];
            this.birthProofIMG = "assets/images/birth_proof.png";
          }
          if (controlName == 'idCard') {
            this.idCardLength = 0;
            this.idCard = [];
            this.idCardIMG = "assets/images/id-card.png";
          }
          if (controlName == 'addressProof') {
            this.addressProofLength = 0;
            this.addressProof = [];
            this.addressProofIMG = "assets/images/address_proof.png";
          }
          if (controlName == 'photo') {
            this.photoLength = 0;
            this.photo = [];
            this.photoIMG = "assets/images/photo.png";
          }
          this.admitcardForm.controls[controlName].setErrors({ 'allowedFileExt': true });
          return;
        }

        if (ext == 'pdf') {
          if (event.target.files[i].size >= 1000000) {
            if (controlName == 'railwayConcessionCertificate') {
              this.railwayConcessionCertificateLength = 0;
              this.railwayConcessionCertificate = [];
              this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
            }
            if (controlName == 'disabilityCertificate') {
              this.disabilityCertificateLength = 0;
              this.disabilityCertificate = [];
              this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
            }
            if (controlName == 'birthProof') {
              this.birthProofLength = 0;
              this.birthProof = [];
              this.birthProofIMG = "assets/images/birth_proof.png";
            }
            if (controlName == 'idCard') {
              this.idCardLength = 0;
              this.idCard = [];
              this.idCardIMG = "assets/images/id-card.png";
            }
            if (controlName == 'addressProof') {
              this.addressProofLength = 0;
              this.addressProof = [];
              this.addressProofIMG = "assets/images/address_proof.png";
            }
            if (controlName == 'photo') {
              this.photoLength = 0;
              this.photo = [];
              this.photoIMG = "assets/images/photo.png";
            }
            this.admitcardForm.controls[controlName].setErrors({ 'maxallowedFileSize': true });
            return;
          }

          if (controlName == 'railwayConcessionCertificate') {
            this.railwayConcessionCertificateLength = filesLength;
            this.railwayConcessionCertificate.push(event.target.files[i]);
            this.railwayConcessionCertificateIMG = "assets/images/concession_certification_upload.png";
          }
          if (controlName == 'disabilityCertificate') {
            this.disabilityCertificateLength = filesLength;
            this.disabilityCertificate.push(event.target.files[i]);
            this.disabilityCertificateIMG = "assets/images/concession_certification_upload.png";
          }
          if (controlName == 'birthProof') {
            this.birthProofLength = filesLength;
            this.birthProof.push(event.target.files[i]);
            this.birthProofIMG = "assets/images/birth_proof_upload.png";
          }
          if (controlName == 'idCard') {
            this.idCardLength = filesLength;
            this.idCard.push(event.target.files[i]);
            this.idCardIMG = "assets/images/id-card_upload.png";
          }
          if (controlName == 'addressProof') {
            this.addressProofLength = filesLength;
            this.addressProof.push(event.target.files[i]);
            this.addressProofIMG = "assets/images/address_proof_upload.png";
          }
          if (controlName == 'photo') {
            this.photoLength = filesLength;
            this.photo.push(event.target.files[i]);
            this.photoIMG = "assets/images/photo_uploaded.png";
          }

        } else {
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = (_event) => {
            this.imageCompress.compressFile(_event.target["result"], -1, 50, 50).then(
              result => {
                fileSize = this.imageCompress.byteCount(result);
                let blobData = this.dataURItoBlob(result, event.target.files[i].type);
                newImagefile = new File([blobData], event.target.files[i].name, { type: event.target.files[i].type });
                // max 1mb  file size validation
                if (fileSize >= 1000000) {
                  if (controlName == 'railwayConcessionCertificate') {
                    this.railwayConcessionCertificateLength = 0;
                    this.railwayConcessionCertificate = [];
                    this.railwayConcessionCertificateIMG = "assets/images/concession_certification.png";
                  }
                  if (controlName == 'disabilityCertificate') {
                    this.disabilityCertificateLength = 0;
                    this.disabilityCertificate = [];
                    this.disabilityCertificateIMG = "assets/images/disability_certificate.png";
                  }
                  if (controlName == 'birthProof') {
                    this.birthProofLength = 0;
                    this.birthProof = [];
                    this.birthProofIMG = "assets/images/birth_proof.png";
                  }
                  if (controlName == 'idCard') {
                    this.idCardLength = 0;
                    this.idCard = [];
                    this.idCardIMG = "assets/images/id-card.png";
                  }
                  if (controlName == 'addressProof') {
                    this.addressProofLength = 0;
                    this.addressProof = [];
                    this.addressProofIMG = "assets/images/address_proof.png";
                  }
                  if (controlName == 'photo') {
                    this.photoLength = 0;
                    this.photo = [];
                    this.photoIMG = "assets/images/photo.png";
                  }
                  this.admitcardForm.controls[controlName].setErrors({ 'maxallowedFileSize': true });
                  return;
                }

                if (controlName == 'railwayConcessionCertificate') {
                  this.railwayConcessionCertificateLength = filesLength;
                  this.railwayConcessionCertificate.push(newImagefile);
                  this.railwayConcessionCertificateIMG = "assets/images/concession_certification_upload.png";
                }
                if (controlName == 'disabilityCertificate') {
                  this.disabilityCertificateLength = filesLength;
                  this.disabilityCertificate.push(newImagefile);
                  this.disabilityCertificateIMG = "assets/images/concession_certification_upload.png";
                }
                if (controlName == 'birthProof') {
                  this.birthProofLength = filesLength;
                  this.birthProof.push(newImagefile);
                  this.birthProofIMG = "assets/images/birth_proof_upload.png";
                }
                if (controlName == 'idCard') {
                  this.idCardLength = filesLength;
                  this.idCard.push(newImagefile);
                  this.idCardIMG = "assets/images/id-card_upload.png";
                }
                if (controlName == 'addressProof') {
                  this.addressProofLength = filesLength;
                  this.addressProof.push(newImagefile);
                  this.addressProofIMG = "assets/images/address_proof_upload.png";
                }
                if (controlName == 'photo') {
                  this.photoLength = filesLength;
                  this.photo.push(newImagefile);
                  this.photoIMG = "assets/images/photo_uploaded.png";
                }
              }
            );
          }
        }
      }

    }

  }

  dataURItoBlob(dataURI, type) {
    dataURI = dataURI.replace(/data\:image\/(jpeg|jpg|png)\;base64\,/gi, '');
    let sliceSize = 1024;
    let byteCharacters = atob(dataURI);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: type });
    return blob;
  }

  getHospital() {
    this._commonService.get('/hospitals').subscribe(data => {
      if (data.status == 200) {
        this.hospital = []
        data.body['result'].hospitals.forEach((ele, index) => {
          this.hospital.push(ele)
        })
      }
    }, error => {
      console.log(error)
    })
  }

  /**
   * get hospital by division
   */
  hospitalByDivisionId(divisionId) {
    this._commonService.get("/hospitalbydivisionid?divisionId=" + divisionId).subscribe((data) => {
      if (data) {
        this.hospital = []
        data.body['result'].hospitals.forEach((ele, index) => {
          this.hospital.push(ele)
        })
      }
    }, err => {
      this._toastrService.error("", "Some error occured");
    })

  }
  getAllDevisions() {
    this._commonService.getSecure('/divisionlist').subscribe((res: any) => {
      if (res) {
        this.divisions = res;
      }
    })
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    if (this.admitcardForm.controls[controlName].value != null) {
      let data = this.admitcardForm.controls[controlName].value;
      try {
        data = data != null ? data.trim() : null;
        if (!data || !data.length || data.length == 0) {
          this.admitcardForm.controls[controlName].setValue(this.admitcardForm.controls[controlName].value.trim());
        }
      } catch (error) {

      }
    }
    const control = this.admitcardForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType);
    return result;
  }

  textToSpeach(textspeach: string) {
    textspeach = this.translate.instant(textspeach);
    const speech = new Speech();
    speech
      .init({
        volume: 0.5,
        lang: "hi-IN",
        rate: 1,
        pitch: 1,

      })
      .then(data => {
        speech.speak({
          text: textspeach,
          queue: false,
        })
          .then(data => {
            //console.log("Success !", data);
          })
          .catch(e => {
            console.error("An error occurred :", e);
          });
      })
      .catch(e => {
        console.error("An error occured while initializing : ", e);
      });
  }
}
