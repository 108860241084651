import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { throwError } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	constructor(private _ngxSpinnerService: NgxSpinnerService) { }
	private totalRequests: number = 0;

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		this.totalRequests++;
		this._ngxSpinnerService.show();

		const token: string = localStorage.getItem('token');

		if (token) {
			request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
		}
		if (!request.headers.has('Content-Type') && !request.headers.has('No-Content-Type')) {
			request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
		}
		if (request.headers.has('No-Content-Type')) {
			request = request.clone({ headers: request.headers.delete('No-Content-Type') });
		}
		request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

		return next.handle(request).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				this.decreaseRequests();
				return event
			}
		}), catchError((error: HttpErrorResponse) => {
			let data = {}
			data = {
				reason: error && error.error.reason ? error.error.reason : '',
				status: error.status
			}
			this.decreaseRequests();
			return throwError(error);
		})
		)
	}
	private decreaseRequests() {
		this.totalRequests = this.totalRequests - 1;
		if (this.totalRequests === 0) {
			this._ngxSpinnerService.hide();
		}
	}
}