import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  apiURL: any = environment.API_ENDPOINT
  secureapiURL: any = environment.API_ENDPOINT_SECURE;
  constructor(
    private _http: HttpClient
  ) { }

  post(url: any, data: any) {
    return this._http.post(this.apiURL + url, data, { observe: 'response' })
  }

  get(url: any) {
    return this._http.get(this.apiURL + url, { observe: 'response' })
  }

  delete(url: any) {
    return this._http.delete(this.apiURL + url, { observe: 'response' })
  }
  post_form(url: any, data: any) {
    return this._http.post(this.secureapiURL + url, data, { observe: 'response', headers: { 'No-Content-Type': '' } })
  }
  get_secure(url: any) {
    return this._http.get(this.secureapiURL + url, { observe: 'response' })
  }

}
