import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../shared/components/confirm/confirm.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LostCardService } from '../../services/lostCard/lost-card.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/services/common/setting-service.service';
@Component({
  selector: 'app-lost-card',
  templateUrl: './lost-card.component.html',
  styleUrls: ['./lost-card.component.scss']
})
export class LostCardComponent implements OnInit {

  lostCardForm: FormGroup;
  settings = {
    termsAndCondition: ""
  }
  constructor(
    private _settingService: SettingService,
    private _lostCardService: LostCardService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastrService: ToastrService,
    private SimpleModalService: SimpleModalService
  ) { }

  ngOnInit() {
    this.inItLostCard();
  }

  inItLostCard() {
    this.lostCardForm = this._formBuilder.group({
      phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
      termsAndCon: ['', [Validators.required]]
    });
  }

  showConfirm() {
    const controls = this.lostCardForm.controls;

    /** check form */
    if (this.lostCardForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    if (this.lostCardForm.controls.termsAndCon.value != true) {
      return;
    }
    this.sendRequestForLostCard();
    // 
  }

  getTermsAndConditions() {
    this.SimpleModalService.addModal(ConfirmComponent, {
      title: 'Tearms And Conditions',
      message: this.settings.termsAndCondition
    })
      .subscribe((isConfirmed) => {
        // Get modal result
        if (isConfirmed) {
          // this.sendRequestForLostCard();
        }
      });
  }

  getAllSettingDetails() {
    this._settingService.getAllSettings().subscribe((res) => {
      if (res && res["statusCode"] == 200) {
        let data = res["result"];
        this.settings.termsAndCondition = data["TERMS_AND_CONDITION"];
        this.getTermsAndConditions();
      }
    })
  }

  sendRequestForLostCard() {
    this._lostCardService.submitLostCardReq(this.lostCardForm.controls.phoneNo.value).subscribe((res) => {
      if (res && res["body"] && res["body"]["message"] && res["body"]["statusCode"] != 200) {
        this._toastrService.warning(res["body"]["message"]);
      }
      if (res && res["body"] && res["body"]["message"] && res["body"]["statusCode"] == 200) {
        this._toastrService.success(res["body"]["message"]);
        this._router.navigate(['/'])
      }

    })

  }

  // convenience getter for easy access to form fields
  get lostCardControl() { return this.lostCardForm.controls; }
}
