import { Component, OnInit, } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from './../../services/common/common.service';
import { PostService } from './../../services/post/post.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { MyIdCardService } from 'src/app/services/my-id-card/my-id-card.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {


  hospital: Array<any> = []
  renewalcardForm: FormGroup;
  submitted: boolean = false
  maxDate: Date;
  railwayConcessionCertificate: any = '';
  disabilityCertificate: any = '';
  birthProof: any = '';
  idCard: any = '';
  addressProof: any = '';
  photo: any = '';
  railwayConcessionCertificateIMG: string = "assets/images/concession_certification.png";
  disabilityCertificateIMG: string = "assets/images/disability_certificate.png";
  birthProofIMG: string = "assets/images/birth_proof.png";
  idCardIMG: string = "assets/images/id-card.png";
  addressProofIMG: string = "assets/images/address_proof.png";
  photoIMG: string = "assets/images/photo.png";
  noImage: string = "assets/images/no-img.png";


  getMobileNo: any;
  IDCard: any;
  editIDCard: boolean = false;
  _albums: Array<any> = [];

  constructor(
    private _myIdCardService: MyIdCardService,
    private _commonService: CommonService,
    private _postService: PostService,
    public translate: TranslateService,
    private _lightbox: Lightbox,
    public sanitizer: DomSanitizer,
    private _toastrService: ToastrService
  ) { }

  open(imageList) {
    this._albums = [];
    if (imageList.length > 0) {
      for (let i = 0; i < imageList.length; i++) {
        var ext = imageList[i].substr(imageList[i].lastIndexOf('.') + 1);
        if (ext) {
          var imagetype = 'data:image/jpg;base64,';
          if (ext == 'pdf') {
            imagetype = 'data:application/pdf;base64,';
          } else if (ext != '') {
            imagetype = 'data:image/' + ext + ';base64,';
          }

          this._postService.get_secure('/applicantdocument?filename=' + imageList[i]).subscribe(data => {
            if (data['status'] == 200) {
              let imsgesrc = imagetype + data['body']['result'].image;
              this._albums.push({
                src: imsgesrc,
                caption: "",
                thumb: imsgesrc
              });

              if (ext == 'pdf') {
                //var win = window.open();
                //win.document.write('<iframe src="' + imsgesrc + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                const downloadLink = document.createElement("a");
                const fileName = imageList[i];

                downloadLink.href = imsgesrc;
                downloadLink.download = fileName;
                downloadLink.click();
              }
            }
          }, error => {
            console.log(error)
          })
        }

      }
      if (this._albums) {
        if (ext == 'pdf' && ext != '') {
          //var win = window.open();
          //win.document.write('<iframe src="' + imsgesrc + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        } else if (ext != '') {
          this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true });

        } else {
          this._toastrService.error("Document Not Found", 'Error')
        }

      }
    }

  }

  close(): void {
    this._lightbox.close();
  }

  ngOnInit() {
    this.getMobileNo = localStorage.getItem('mobileNo');
    this._commonService.getApplicantCardDetail(this.getMobileNo).subscribe(data => {
      if (data.status && data.body) {
        let res = data.body['result']['applicantDetail'];
        this.IDCard = res;
        let division = localStorage.getItem('divisionId');
        this.IDCard.divisionId = this.IDCard.divisionId ? this.IDCard.divisionId : division;
        if (this.IDCard.dateOfBirth) {
          this.IDCard.dateOfBirth = moment(this.IDCard.dateOfBirth).format('DD/MM/YYYY')
        } else {
          this.IDCard.dateOfBirth = '';
        }
        // this.getApplicantDocuments(this.IDCard.photo.length > 0 ? this.IDCard.photo[0] : "");
        if (this.IDCard.photo != null && this.IDCard.photo.length > 0) {
          this.getApplicantDocuments(this.IDCard.photo[0]);
        }

      }
    });



  }
  getApplicantDocuments(filename) {
    let data;

    this._myIdCardService.getApplicantPhoto(filename).subscribe(res => {
      if (res && res.body && res.body["result"] && res.body["result"]["image"]) {
        data = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/' + filename.split(".").pop() + ';base64,' + res.body["result"]["image"]);
        this.IDCard.photo = data;
      }
    });
  }



}
