import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConcessionDocumentService {

  constructor(private _httpClient: HttpClient) { }

  getActiveDocumentList() {    
    let url = environment.API_ENDPOINT + "/guidelineDocuments";
    return this._httpClient.get(url);
}
documentDownload(documentId,fileName){      
  let url = environment.API_ENDPOINT + "/guidelineDocument/download?id=" + documentId  ;
  return this._httpClient.get(url, { observe: 'response'  });

}

}
